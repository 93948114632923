import React, { FormEvent, useEffect, useState } from 'react';

import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import { Link } from 'react-router-dom';
import OrderModel from '../../../models/order.model';
import IPrivateAdminScreenProps from '../private.admin.screen.props';
import moment from 'moment';
import OrderStatusComponent from '../../../components/order-status/order-status.component';

function OrdersAdminScreen(props: IPrivateAdminScreenProps) {
    const api = useApi();
    const alert = useAlert();

    const [orders, setOrders] = useState<OrderModel[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<OrderModel[]>([]);
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        props.setLoading(true);
        api.GET<OrderModel[]>(`/admin/order`, props.token).then((response: OrderModel[]) => {
            setOrders(response);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    }, []);

    useEffect(() => {
        setFilteredOrders(orders.filter((order: OrderModel) => {
            return (
                order.user.email.toLowerCase().includes(filter.toLowerCase()) || 
                order.user.name.toLowerCase().includes(filter.toLowerCase()) ||
                order.user.last_name.toLowerCase().includes(filter.toLowerCase()) || 
                order.order_status?.name.toLowerCase().includes(filter.toLowerCase()) ||
                order.id.toString().toLowerCase().includes(filter.toLowerCase()) ||
                order.applicant_name.toLowerCase().includes(filter.toLowerCase()) ||
                order.user.company.toLowerCase().includes(filter.toLowerCase())
            );
        }));
    }, [orders, filter]);

    const onChangeFilter = (event: FormEvent<HTMLInputElement>) : void => {
        setFilter(event.currentTarget.value);
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Pedidos
                    </h2>
                </div>
            </div>
            <div className='row align-items-center mb-3 mx-0'>
                <div className='col-12 mb-3 mb-md-0'>
                    <input type='text' className='form-control' placeholder='Filtrar...' onChange={onChangeFilter} />
                </div>
            </div>
            <div className='row align-items-center m-0'>
                {
                    filteredOrders.length ? (
                        filteredOrders.map((order: OrderModel) => (
                            <Link to={`/admin/inicio/pedido/${order.id}`} key={order.id} 
                                className='col-12 col-sm-6 col-md-4 col-lg-3 text-light text-decoration-none my-1'>
                                <div className='row align-items-center bg-light text-dark p-3 mx-0'>
                                    <div className='col-6'>
                                        <p className='m-0 text-primary'>
                                            <b>
                                                {order.user.last_name}
                                            </b>
                                        </p>
                                        <p className='m-0'>
                                            <small>
                                                {order.user.name}
                                            </small>
                                        </p>
                                        <p className='m-0 text-primary'>
                                            <small>
                                                Cant. {order.order_books.length}
                                            </small>
                                        </p>
                                    </div>
                                    <div className='col-6 text-end'>
                                        <p className='m-0'>
                                            <small>
                                                #{order.id}
                                            </small>
                                        </p>
                                        <p className='m-0'>
                                            <small>
                                                {moment(order.date).format("DD/MM/YYYY")}
                                            </small>
                                        </p>
                                        {
                                            order.order_status ? (
                                                <div className='mt-2'>
                                                    <OrderStatusComponent status={order.order_status} is_paused={order.is_paused} />
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className='col-12'>
                            <div className='alert alert-warning'>
                                <p className='m-0'>
                                    <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                    No existen pedidos registrados que cumplan con los filtros de búsqueda
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default OrdersAdminScreen;
