import React, { ChangeEvent, useEffect, useState } from 'react';
import IPrivateAdminScreenProps from '../private.admin.screen.props';
import moment from 'moment';
import OrderStatusModel from '../../../models/order-status.model';
import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import UserModel from '../../../models/user.model';
import OrderModel from '../../../models/order.model';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";

interface ICalendarAdminScreen extends IPrivateAdminScreenProps {};

function CalendarAdminScreen(props: ICalendarAdminScreen) {
    const api = useApi();
    const alert = useAlert();

    const [startDate, setStartDate] = useState<string>(moment().utc(true).startOf('month').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState<string>(moment().utc(true).endOf('month').add(-1, 'day').format("YYYY-MM-DD"));
    const [orderStatusId, setOrderStatusId] = useState<number>();
    const [userId, setUserId] = useState<number>();

    const [orderStatuses, setOrderStatuses] = useState<OrderStatusModel[]>([]);
    const [users, setUsers] = useState<UserModel[]>([]);
    const [orders, setOrders] = useState<OrderModel[]>([]);

    const [collapsedYears, setCollapsedYears] = useState<number[]>([]);
    const [collapsedMonths, setCollapsedMonths] = useState<string[]>([]);
    const [collapsedDays, setCollapsedDays] = useState<string[]>([]);

    useEffect(() => {
        props.setLoading(true);
        api.GET<OrderStatusModel[]>(`/admin/order-status`, props.token).then((orderStatuses: OrderStatusModel[]) => {
            setOrderStatuses(orderStatuses);
            api.GET<UserModel[]>(`/admin/user`, props.token).then((users: UserModel[]) => {
                setUsers(users);
                getData().then(() => {
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setCollapsedYears([
            ...getYears(),
        ]);

        setCollapsedMonths(getYears().flatMap((year: number) => {
            return getMonths(year).map((month: number) => {
                return `${year}-${month}`;
            });
        }));

        setCollapsedDays(getYears().flatMap((year: number) => {
            return getMonths(year).flatMap((month: number) => {
                return getDays(year, month).map((day: number) => {
                    return `${year}-${month}-${day}`;
                });
            });
        }));

        // eslint-disable-next-line
    }, [orders])

    const getData = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            api.GET<OrderModel[]>(`/admin/calendar/${startDate}/${endDate}/${userId || 0}/${orderStatusId || 0}`, props.token).then((orders: OrderModel[]) => {
                setOrders(orders);
                resolve();
            }, (error: string) => {
                reject(error);
            });
        });
    }

    const onChangeStartDate = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value) {
            setStartDate(event.target.value);
        }
    }

    const onChangeEndDate = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value) {
            setEndDate(event.target.value);
        }
    }

    const onChangeOrderStatusId = (event: ChangeEvent<HTMLSelectElement>): void => {
        setOrderStatusId(event.target.value ? parseInt(event.target.value) : undefined);
    }

    const onChangeUserId = (event: ChangeEvent<HTMLSelectElement>): void => {
        setUserId(event.target.value ? parseInt(event.target.value) : undefined);
    }

    const onSearch = (): void => {
        props.setLoading(true);
        getData().then(() => {
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    }

    const getExportData = (): { [k: string]: string; }[] => {
        let data: { [k: string]: string; }[] = [
            ...orders.map((order: OrderModel) => {
                return {
                    'Nº Pedido': order.id.toString(),
                    'Cliente': `${order.user.name} ${order.user.last_name}`,
                    'Fecha de ingreso': order.date ? moment(order.date).format("DD-MM-YYYY") : "",
                    'Fecha estimada de entrega': order.estimated_delivery_date ? moment(order.estimated_delivery_date).format("DD-MM-YYYY") : "",
                    'Cantidad de libros': order.order_books.length.toString(),
                    'Dirección': order.pickup_address,
                    'Rango horario': `${order.time_range_start} - ${order.time_range_end}`,
                    'Whatsapp': order.applicant_whatsapp,
                };
            }),
        ];

        return data;
    }

    const getYears = (): number[] => {
        return orders.map((order: OrderModel) => {
            return order.estimated_delivery_date ? moment(order.estimated_delivery_date).year() : 0;
        }).filter((year: number, index: number, array: number[]) => {
            return (array.indexOf(year) === index);
        });
    }

    const onToggleYear = (year: number): void => {
        let index: number = collapsedYears.indexOf(year);
        if (index >= 0) {
            let newCollapsedYears: number[] = collapsedYears;
            newCollapsedYears.splice(index, 1);
            setCollapsedYears([
                ...newCollapsedYears,
            ]);
        }
        else {
            setCollapsedYears([
                ...collapsedYears,
                year,
            ]);
        }
    }

    const isYearCollpased = (year: number): boolean => {
        return collapsedYears.some((y: number) => {
            return (y === year);
        });
    }

    const getMonths = (year: number): number[] => {
        return orders.filter((order: OrderModel) => {
            return order.estimated_delivery_date ? (moment(order.estimated_delivery_date).year() === year) : false;
        }).map((order: OrderModel) => {
            return order.estimated_delivery_date ? moment(order.estimated_delivery_date).month() : 0;
        }).filter((month: number, index: number, array: number[]) => {
            return (array.indexOf(month) === index);
        });
    }

    const onToggleMonth = (year: number, month: number): void => {
        let index: number = collapsedMonths.indexOf(`${year}-${month}`);
        if (index >= 0) {
            let newCollapsedMonths: string[] = collapsedMonths;
            newCollapsedMonths.splice(index, 1);
            setCollapsedMonths([
                ...newCollapsedMonths,
            ]);
        }
        else {
            setCollapsedMonths([
                ...collapsedMonths,
                `${year}-${month}`,
            ]);
        }
    }

    const isMonthCollpased = (year: number, month: number): boolean => {
        return collapsedMonths.some((m: string) => {
            return (m === `${year}-${month}`);
        });
    }

    const getDays = (year: number, month: number): number[] => {
        return orders.filter((order: OrderModel) => {
            return order.estimated_delivery_date ? (moment(order.estimated_delivery_date).year() === year && moment(order.estimated_delivery_date).month() === month) : false;
        }).map((order: OrderModel) => {
            return order.estimated_delivery_date ? moment(order.estimated_delivery_date).date() : 0;
        }).filter((day: number, index: number, array: number[]) => {
            return (array.indexOf(day) === index);
        });
    }

    const onToggleDay = (year: number, month: number, day: number): void => {
        let index: number = collapsedDays.indexOf(`${year}-${month}-${day}`);
        if (index >= 0) {
            let newCollapsedDays: string[] = collapsedDays;
            newCollapsedDays.splice(index, 1);
            setCollapsedDays([
                ...newCollapsedDays,
            ]);
        }
        else {
            setCollapsedDays([
                ...collapsedDays,
                `${year}-${month}-${day}`,
            ]);
        }
    }

    const isDayCollpased = (year: number, month: number, day: number): boolean => {
        return collapsedDays.some((d: string) => {
            return (d === `${year}-${month}-${day}`);
        });
    }

    const getOrders = (year: number, month: number, day: number): OrderModel[] => {
        return orders.filter((order: OrderModel) => {
            if (order.estimated_delivery_date) {
                return (
                    (moment(order.estimated_delivery_date).year() === year) &&
                    (moment(order.estimated_delivery_date).month() === month) &&
                    (moment(order.estimated_delivery_date).date() === day)
                );
            }
            else {
                return false;
            }
        });
    }

    const getOrderStatusTextColor = (order: OrderModel): string => {
        if (order.is_paused) {
            return "text-orange";
        }
        else if (order.order_status?.is_pending) {
            return "text-danger";
        }
        else if (order.order_status?.is_working) {
            return "text-warning";
        }
        else if (order.order_status?.is_finished) {
            return "text-success";
        }
        else {
            return "text-white";
        }
    }

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Calendario
                    </h2>
                </div>
            </div>
            <div className='row align-items-center mb-3 mx-0'>
                <div className='col-12 col-md-6 col-xl-3 mb-3'>
                    <label className='form-label'>
                        Desde
                    </label>
                    <input type='date' className='form-control' placeholder='' value={moment(startDate).format("YYYY-MM-DD")} 
                        onChange={onChangeStartDate} />
                </div>
                <div className='col-12 col-md-6 col-xl-3 mb-3'>
                    <label className='form-label'>
                        Hasta
                    </label>
                    <input type='date' className='form-control' placeholder='' value={moment(endDate).format("YYYY-MM-DD")} 
                        onChange={onChangeEndDate} />
                </div>
                <div className='col-12 col-md-6 col-xl-3 mb-3'>
                    <label className='form-label'>
                        Cliente
                    </label>
                    <select className='form-select' value={userId} onChange={onChangeUserId}>
                        <option value={undefined}>
                            TODOS
                        </option>
                        {
                            users.map((user: UserModel) => (
                                <option value={user.id} key={user.id}>
                                    { user.name } { user.last_name }
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-12 col-md-6 col-xl-3 mb-3'>
                    <label className='form-label'>
                        Estado
                    </label>
                    <select className='form-select' value={orderStatusId} onChange={onChangeOrderStatusId}>
                        <option value={undefined}>
                            TODOS
                        </option>
                        {
                            orderStatuses.map((orderStatus: OrderStatusModel) => (
                                <option value={orderStatus.id} key={orderStatus.id}>
                                    { orderStatus.name }
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-12 text-end mb-3'>
                    <button className='btn btn-primary' onClick={onSearch}>
                        <i className='bi bi-search me-3'></i>
                        Buscar
                    </button>
                    {
                        orders.length ? (
                            <CSVLink className='btn btn-primary ms-3' data={getExportData()} filename='calendario.csv'>
                                <i className='bi bi-download me-3'></i>
                                Exportar
                            </CSVLink>
                        ) : (
                            null
                        )
                    }
                </div>
            </div>
            <div className='row align-items-center m-0'>
                {
                    getYears().map((year: number) => (
                        <div className='col-12 text-light text-decoration-none my-1' key={year}>
                            <button className='btn btn-link p-0 d-flex flex-row align-items-center text-decoration-none' 
                                onClick={() => onToggleYear(year)}>
                                <p className='m-0 text-white'>
                                    <b>
                                        { year }
                                    </b>
                                </p>

                                {
                                    isYearCollpased(year) ? (
                                        <i className='bi bi-chevron-down ms-3 text-white'></i>
                                    ) : (
                                        <i className='bi bi-chevron-up ms-3 text-white'></i>
                                    )
                                }
                            </button>

                            {
                                !isYearCollpased(year) ? (
                                    getMonths(year).map((month: number) => (
                                        <div className='col-12 text-light text-decoration-none my-1 ps-3' key={`${year}-${month}`}>
                                            <button className='btn btn-link p-0 d-flex flex-row align-items-center text-decoration-none' 
                                                onClick={() => onToggleMonth(year, month)}>
                                                <p className='m-0 text-white'>
                                                    <b>
                                                        Mes { month + 1 }
                                                    </b>
                                                </p>
            
                                                {
                                                    isMonthCollpased(year, month) ? (
                                                        <i className='bi bi-chevron-down ms-3 text-white'></i>
                                                    ) : (
                                                        <i className='bi bi-chevron-up ms-3 text-white'></i>
                                                    )
                                                }
                                            </button>

                                            {
                                                !isMonthCollpased(year, month) ? (
                                                    getDays(year, month).map((day: number) => (
                                                        <div className='col-12 text-light text-decoration-none my-1 ps-3' key={`${year}-${month}-${day}`}>
                                                            <button className='btn btn-link p-0 d-flex flex-row align-items-center text-decoration-none' 
                                                                onClick={() => onToggleDay(year, month, day)}>
                                                                <p className='m-0 text-white'>
                                                                    <b>
                                                                        { moment(`${year}-${month + 1}-${day}`).format("DD/MM/YYYY") }
                                                                    </b>
                                                                </p>
                            
                                                                {
                                                                    isDayCollpased(year, month, day) ? (
                                                                        <i className='bi bi-chevron-down ms-3 text-white'></i>
                                                                    ) : (
                                                                        <i className='bi bi-chevron-up ms-3 text-white'></i>
                                                                    )
                                                                }
                                                            </button>

                                                            {
                                                                !isDayCollpased(year, month, day) ? (
                                                                    getOrders(year, month, day).map((order: OrderModel) => (
                                                                        <div className='col-12 my-1 ps-3' key={order.id}>
                                                                            <Link to={`/admin/inicio/pedido/${order.id}`} 
                                                                                className={`m-0 text-decoration-none ${getOrderStatusTextColor(order)}`}>
                                                                                Pedido Nª { order.id } - { order.user.name } { order.user.last_name } - { order.order_status?.name } { order.is_paused ? '(Pausado)' : "" }
                                                                            </Link>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                    ))
                                ) : (
                                    null
                                )
                            }
                        </div>
                    ))
                }
            </div>
        </>
    );
};

export default CalendarAdminScreen;
