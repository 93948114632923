import React from 'react';
import { Link } from 'react-router-dom';
import IPrivateScreenProps from '../private.screen.props';

interface IMainScreenProps extends IPrivateScreenProps {
    onAfterLogout: () => void;
};

function MainScreen(props: IMainScreenProps) {
    return (
        <div className='row m-0 align-items-center'>
            <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 p-0 mx-auto'>
                <div className='row m-0 align-items-center'>
                    <Link to={'/clientes'} className='col-6 p-3 bg-primary text-light text-center text-decoration-none'>
                        <i className='bi bi-people-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Clientes
                        </p>
                    </Link>
                    {
                        !props.user.is_operator ? (
                            <Link to={'/operadores'} className='col-6 p-3 bg-secondary text-light text-center text-decoration-none'>
                                <i className='bi bi-person-fill-gear' style={{
                                    fontSize: 50,
                                }}></i>
                                <p className='m-0 text-uppercase fw-bold'>
                                    Operadores
                                </p>
                            </Link>
                        ) : (
                            null
                        )
                    }
                    <Link to={'/pedidos'} className={`col-6 p-3 text-light text-center text-decoration-none bg-secondary`}>
                        <i className='bi bi-briefcase-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Pedidos
                        </p>
                    </Link>
                    <Link to={'/chats'} 
                        className={`col-6 p-3 text-light text-center text-decoration-none ${props.user.is_operator ? 'bg-secondary' : 'bg-primary'}`}>
                        <i className='bi bi-chat-left-text-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Chats
                        </p>
                    </Link>
                    <Link to={'/membresia'} className={`col-6 p-3 text-light text-center text-decoration-none bg-primary`}>
                        <i className='bi bi-person-vcard-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Membresía
                        </p>
                    </Link>
                    <Link to={'/mis-datos'} 
                        className={`col-6 p-3 text-light text-center text-decoration-none ${props.user.is_operator ? 'bg-primary' : 'bg-secondary'}`}>
                        <i className='bi bi-info-circle-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Mis datos
                        </p>
                    </Link>
                    <button className={`col-6 p-3 text-light text-center border-0 bg-secondary`} 
                        onClick={props.onAfterLogout}>
                        <i className='bi bi-x-circle-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Salir
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MainScreen;
