import React, { createRef, FormEvent, useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import IPrivateScreenProps from '../private.screen.props';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import OrderModel from '../../models/order.model';
import moment from 'moment';
import CustomerModel from '../../models/customer.model';
import FileSelectComponent from '../../components/file-select/file-select.component';
import UserMembershipModel from '../../models/user-membership.model';
import OrderTypeModel from '../../models/order-type.model';
import BookTypeModel from '../../models/book-type.model';
import CopySystemModel from '../../models/copy-system.model';
import OrderFileModel from '../../models/order-file.model';
import OrderBookModel from '../../models/order-book.model';
import constants from '../../constants/constants';

const times : string[] = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
];

const foliosQuantity : number[] = [
    100,
    200,
    250,
    500,
    1000,
];

function OrderScreen(props: IPrivateScreenProps) {
    let { id } = useParams();

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    let fileSelectRef : React.RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    const [membership, setMembership] = useState<UserMembershipModel>();
    const [customers, setCustomers] = useState<CustomerModel[]>([]);
    const [orderTypes, setOrderTypes] = useState<OrderTypeModel[]>([]);
    const [bookTypes, setBookTypes] = useState<BookTypeModel[]>([]);
    const [copySystems, setCopySystems] = useState<CopySystemModel[]>([]);
    const [order, setOrder] = useState<OrderModel>({
        id: 0,
        user_id: props.user.id,
        user: props.user,
        date: undefined,
        order_status_id: undefined,
        order_status: undefined,
        scan: "",
        applicant_email: "",
        applicant_name: "",
        applicant_whatsapp: "",
        time_range_start: "",
        time_range_end: "",
        deliver_date: undefined,
        order_files: [],
        order_books: [],
        admin_url: "",
        scan_url: "",
        scan_admin_url: "",
        observations: "",
        is_paused: false,
        pickup_address: props.user.address,
        estimated_delivery_date: undefined,
        order_delivery_status_id: undefined,
        order_delivery_status: undefined,
        next_order_delivery_status: undefined,
    });
    const [orderBook, setOrderBook] = useState<OrderBookModel>();

    useEffect(() => {
        props.setLoading(true);
        api.GET<UserMembershipModel>(`/user-membership`, props.token).then((response: UserMembershipModel) => {
            setMembership(response);
            api.GET<CustomerModel[]>(`/customer`, props.token).then((response: CustomerModel[]) => {
                setCustomers(response);
                api.GET<OrderTypeModel[]>(`/order-type`, props.token).then((response: OrderTypeModel[]) => {
                    setOrderTypes(response);
                    api.GET<BookTypeModel[]>(`/book-type`, props.token).then((response: BookTypeModel[]) => {
                        setBookTypes(response);
                        api.GET<CopySystemModel[]>(`/copy-system`, props.token).then((response: CopySystemModel[]) => {
                            setCopySystems(response);
                            if (id) {
                                api.GET<OrderModel>(`/order/${id}`, props.token).then((response: OrderModel) => {
                                    setOrder(response);
                                    props.setLoading(false);
                                }, (error: string) => {
                                    alert.ERROR(error);
                                    props.setLoading(false);
                                });
                            }
                            else {
                                setOrder({
                                    id: 0,
                                    user_id: props.user.id,
                                    user: props.user,
                                    date: undefined,
                                    order_status_id: undefined,
                                    order_status: undefined,
                                    scan: "",
                                    applicant_email: "",
                                    applicant_name: "",
                                    applicant_whatsapp: "",
                                    time_range_start: "",
                                    time_range_end: "",
                                    deliver_date: undefined,
                                    order_files: [],
                                    order_books: [],
                                    admin_url: "",
                                    scan_url: "",
                                    scan_admin_url: "",
                                    observations: "",
                                    is_paused: false,
                                    pickup_address: props.user.address,
                                });
                                setOrderBook(undefined);
                                props.setLoading(false);
                            }
                        }, (error: string) => {
                            alert.ERROR(error);
                            props.setLoading(false);
                        });
                    }, (error: string) => {
                        alert.ERROR(error);
                        props.setLoading(false);
                    });
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });

        // eslint-disable-next-line
    }, [id, props.user]);

    const onChangeApplicantEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setOrder({
            ...order,
            applicant_email: event.currentTarget.value,
        });
    };

    const onChangeApplicantName = (event: FormEvent<HTMLInputElement>) : void => {
        setOrder({
            ...order,
            applicant_name: event.currentTarget.value,
        });
    };

    const onChangeApplicantWhatsapp = (event: FormEvent<HTMLInputElement>) : void => {
        setOrder({
            ...order,
            applicant_whatsapp: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeStart = (event: FormEvent<HTMLSelectElement>) : void => {
        setOrder({
            ...order,
            time_range_start: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeEnd = (event: FormEvent<HTMLSelectElement>) : void => {
        setOrder({
            ...order,
            time_range_end: event.currentTarget.value,
        });
    };

    const onChangeDeliverDate = (event: FormEvent<HTMLInputElement>) : void => {
        setOrder({
            ...order,
            deliver_date: event.currentTarget.value ? moment(event.currentTarget.value).toDate() : undefined,
        });
    };

    const onChangePickupAddress = (event: FormEvent<HTMLInputElement>) : void => {
        setOrder({
            ...order,
            pickup_address: event.currentTarget.value,
        });
    };

    const getOrderFiles = () : string[] => {
        if (!order.id) {
            return order.order_files.map((of: OrderFileModel) => {
                return of.file;
            });
        }
        else {
            return order.order_files.map((of: OrderFileModel) => {
                return of.url ? of.url : of.file;
            });
        }
    };

    const onChangeOrderFiles = (value: string[]) : void => {
        setOrder({
            ...order,
            order_files: value.map((file: string) => {
                return {
                    id: 0,
                    order_id: order.id,
                    file: file,
                    url: "",
                    admin_url: "",
                };
            }),
        });
    };

    const onOpenOrderBookForm = () : void => {
        setOrderBook({
            id: 0,
            order_id: 0,
            order_type_id: undefined,
            order_type: undefined,
            book_type_id: undefined,
            book_type: undefined,
            number: undefined,
            folios_quantity: undefined,
            entry_folio: undefined,
            exit_folio: undefined,
            copy_system_id: undefined,
            copy_system: undefined,
        });
    };

    const onCloseOrderBookForm = () : void => {
        setOrderBook(undefined);
    };

    const onChangeOrderBookCustomerId = (event: FormEvent<HTMLSelectElement>) : void => {
        if (orderBook) {
            setOrderBook({
                ...orderBook,
                customer_id: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
                customer: customers.find((customer: CustomerModel) => {
                    return (customer.id === parseInt(event.currentTarget.value));
                }),
            });
        }
    };

    const onChangeOrderBookOrderTypeId = (event: FormEvent<HTMLSelectElement>) : void => {
        if (orderBook) {
            setOrderBook({
                ...orderBook,
                order_type_id: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
                order_type: orderTypes.find((orderType: OrderTypeModel) => {
                    return (orderType.id === parseInt(event.currentTarget.value));
                }),
            });
        }
    };

    const onChangeOrderBookBookTypeId = (event: FormEvent<HTMLSelectElement>) : void => {
        if (orderBook) {
            setOrderBook({
                ...orderBook,
                book_type_id: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
                book_type: bookTypes.find((bookType: BookTypeModel) => {
                    return (bookType.id === parseInt(event.currentTarget.value));
                }),
            });
        }
    };

    const onChangeOrderBookNumber = (event: FormEvent<HTMLInputElement>) : void => {
        if (orderBook) {
            setOrderBook({
                ...orderBook,
                number: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
            });
        }
    };

    const onChangeOrderBookFoliosQuantity = (event: FormEvent<HTMLSelectElement>) : void => {
        if (orderBook) {
            setOrderBook({
                ...orderBook,
                folios_quantity: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
            });
        }
    };

    const onChangeOrderBookEntryFolio = (event: FormEvent<HTMLInputElement>) : void => {
        if (orderBook) {
            setOrderBook({
                ...orderBook,
                entry_folio: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
            });
        }
    };

    const onChangeOrderBookCopySystemId = (event: FormEvent<HTMLSelectElement>) : void => {
        if (orderBook) {
            setOrderBook({
                ...orderBook,
                copy_system_id: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
                copy_system: copySystems.find((copySystem: CopySystemModel) => {
                    return (copySystem.id === parseInt(event.currentTarget.value));
                }),
            });
        }
    };

    const onSaveOrderBook = () => {
        if (orderBook) {
            if (!orderBook.customer_id) {
                alert.ERROR(`Seleccione el cliente del libro.`);
            }
            else if (!orderBook.order_type_id) {
                alert.ERROR(`Seleccione el tipo de trámite del libro.`);
            }
            else if (!orderBook.book_type_id) {
                alert.ERROR(`Seleccione el tipo de libro.`);
            }
            else if (!orderBook.number) {
                alert.ERROR(`Complete el número de libro.`);
            }
            else if (!orderBook.folios_quantity) {
                alert.ERROR(`Seleccione la cantidad de folios del libro.`);
            }
            else if (!orderBook.entry_folio) {
                alert.ERROR(`Complete el folio de entrada del libro.`);
            }
            else if (orderBook.entry_folio > orderBook.folios_quantity) {
                alert.ERROR(`El folio de entrada no puede ser mayor que la cantidad de folios del libro.`);
            }
            else if (!orderBook.copy_system_id) {
                alert.ERROR(`Seleccione el sistema de copiado del libro.`);
            }
            else {
                let orderBooks : OrderBookModel[] = [
                    ...order.order_books,
                    orderBook,
                ];
                setOrder({
                    ...order,
                    order_books: orderBooks,
                });
                onCloseOrderBookForm();
            }
        }
    };

    const onDeleteOrderBook = (orderBook: OrderBookModel) : void => {
        let index : number = order.order_books.indexOf(orderBook);
        let orderBooks: OrderBookModel[] = order.order_books.splice(index, 1);
        setOrder({
            ...order,
            order_books: orderBooks,
        });
    };

    const onSave = () : void => {
        props.setLoading(true);
        api.POST<void>(`/order`, order, props.token).then(() => {
            navigate(`/pedidos`);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    const isMembershipActive = () : boolean => {
        if (membership && membership.due_date) {
            return (moment(membership.due_date).format("YYYYMMDD") >= moment().format("YYYYMMDD"));
        }
        else {
            return false;
        }
    };

    const onSelectFile = () : void => {
        fileSelectRef.current?.click();
    };

    const toBase64 = (file: File) : Promise<string> => {
        return new Promise((resolve, reject) => {
            let reader: FileReader = new FileReader();
            reader.onload = () : void => {
                if (reader.result) {
                    resolve(reader.result.toString());
                }
                else {
                    reject(constants.UNEXPECTED_ERROR);
                }
            };
            reader.onerror = () : void => {
                reject(constants.UNEXPECTED_ERROR);
            };
            reader.readAsDataURL(file);
        });
    };

    const onAddFile = (event: FormEvent<HTMLInputElement>) : void => {
        if (event.currentTarget.files && event.currentTarget.files.length) {
            props.setLoading(true);
            toBase64(event.currentTarget.files[0]).then((response: string) => {
                if (fileSelectRef.current) {
                    fileSelectRef.current.value = "";
                }
                
                api.PUT<void>(`/order/${order.id}/upload-file`, {
                    file: response,
                }, props.token).then(() => {
                    navigate(0);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        {
                            order.id ? "Ver pedido" : "Nuevo pedido"
                        }
                    </h2>
                </div>
            </div>
            {
                (order.id && order.order_status && order.order_status.is_finished && !isMembershipActive()) ? (
                    <>
                        <div className='row align-items-center mx-0'>
                            <div className='col-12'>
                                <div className='alert alert-warning'>
                                    <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                    No es posible ver el pedido porque no posee una membresía activa.
                                </div>
                            </div>
                        </div>
                        <div className='row align-items-center m-0'>
                            <div className='col-12 mb-3'>
                                <div className='d-grid gap-2'>
                                    <Link to={`/membresia`} className='btn btn-primary'>
                                        <i className='bi bi-person-vcard-fill me-2'></i>
                                        Ver mi mebresía
                                    </Link>
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='d-grid gap-2'>
                                    <Link to={`/pedidos`} className='btn btn-primary'>
                                        <i className='bi bi-chevron-left me-2'></i>
                                        Volver
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='row align-items-center mx-0'>
                            {
                                order.id ? (
                                    <>
                                        <div className='col-12 col-md-6'>
                                            <div className='mb-3'>
                                                <label className='form-label'>
                                                    Fecha
                                                </label>
                                                <input type='text' className='form-control' readOnly={true} 
                                                    value={order.date ? moment(order.date).format("DD/MM/YYYY") : ""} />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <div className='mb-3'>
                                                <label className='form-label'>
                                                    Estado
                                                </label>
                                                <input type='text' className='form-control' readOnly={true} value={order.order_status?.name || ""} />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    null
                                )
                            }
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Correo electrónico del solicitante
                                    </label>
                                    <input type='email' className='form-control' maxLength={255} placeholder='Correo electrónico del solicitante' 
                                        onChange={onChangeApplicantEmail} value={order.applicant_email} readOnly={order.id !== 0} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Nombre del solicitante
                                    </label>
                                    <input type='text' className='form-control' maxLength={100} placeholder='Nombre del solicitante' 
                                        onChange={onChangeApplicantName} value={order.applicant_name} readOnly={order.id !== 0} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        WhatsApp del solicitante
                                    </label>
                                    <input type='text' className='form-control' maxLength={100} placeholder='WhatsApp del solicitante' 
                                        onChange={onChangeApplicantWhatsapp} value={order.applicant_whatsapp} readOnly={order.id !== 0} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Rango horario
                                    </label>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <select className='form-select' onChange={onChangeTimeRangeStart} value={order.time_range_start} 
                                                disabled={order.id !== 0}>
                                                <option value="">
                                                    Desde
                                                </option>
                                                {
                                                    times.map((time: string) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className='col-6'>
                                            <select className='form-select' onChange={onChangeTimeRangeEnd} value={order.time_range_end} 
                                                disabled={order.id !== 0}>
                                                <option value="">
                                                    Hasta
                                                </option>
                                                {
                                                    times.map((time: string) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Fecha de retiro
                                    </label>
                                    <input type='date' className='form-control' placeholder='Fecha de retiro' onChange={onChangeDeliverDate} 
                                        value={order.deliver_date ? moment(order.deliver_date).format("YYYY-MM-DD") : ""} readOnly={order.id !== 0} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Dirección y localidad de retiro
                                    </label>
                                    <input type='text' maxLength={100} className='form-control' placeholder='Dirección y localidad de retiro' 
                                        onChange={onChangePickupAddress} value={order.pickup_address} readOnly={order.id !== 0} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Archivos
                                    </label>
                                    <FileSelectComponent files={getOrderFiles()} setLoading={props.setLoading} onChange={onChangeOrderFiles} 
                                        multiple={true} disabled={order.id !== 0} token={props.token}
                                        accept={['txt', 'pdf', 'doc', 'xls', 'jpg', 'xlsx', 'docx', 'rar', 'zip']} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Libros
                                    </label>
                                    <div className='table-resposive overflow-x-auto'>
                                        <table className='table text-light'>
                                            <thead>
                                                <tr>
                                                    {
                                                        !order.id ? (
                                                            <th className='px-0'>
                                                                <button className='btn btn-primary' onClick={() => onOpenOrderBookForm()}>
                                                                    <i className='bi bi-plus-lg'></i>
                                                                </button>
                                                            </th>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    <th>
                                                        Cliente
                                                    </th>
                                                    <th>
                                                        Tipo de trámite
                                                    </th>
                                                    <th>
                                                        Tipo de libro
                                                    </th>
                                                    <th>
                                                        Nº de libro
                                                    </th>
                                                    <th>
                                                        Cantidad de folios
                                                    </th>
                                                    <th>
                                                        Folio de entrada
                                                    </th>
                                                    {
                                                        order.id ? (
                                                            <th>
                                                                Folio de salida
                                                            </th>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    <th>
                                                        Sistema de copiado
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    order.order_books.map((orderBook: OrderBookModel, index: number) => (
                                                        <tr key={index}>
                                                            {
                                                                !order.id ? (
                                                                    <td className='px-0' valign='middle'>
                                                                        <button className='btn btn-primary' onClick={() => onDeleteOrderBook(orderBook)}>
                                                                            <i className='bi bi-trash-fill'></i>
                                                                        </button>
                                                                    </td>
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                            <td valign='middle'>
                                                                {orderBook.customer?.name}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.order_type?.name} ({orderBook.order_type?.description})
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.book_type?.name}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.number}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.folios_quantity}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.entry_folio}
                                                            </td>
                                                            {
                                                                order.id ? (
                                                                    <td valign='middle'>
                                                                        {orderBook.exit_folio}
                                                                    </td>
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                            <td valign='middle'>
                                                                {orderBook.copy_system?.name}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                order.order_status?.is_finished ? (
                                    <div className='col-12'>
                                        <div className='mb-3'>
                                            <label className='form-label'>
                                                Escaneos
                                            </label>
                                            <FileSelectComponent files={[order.scan_url]} setLoading={props.setLoading} disabled={true} multiple={false}
                                                 token={props.token} accept={['pdf', 'jpg']} />
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            {
                                order.id ? (
                                    <div className='col-12'>
                                        <div className='mb-3'>
                                            <label className='form-label'>
                                                Observaciones
                                            </label>
                                            <textarea className='form-control' rows={5} value={order.observations} readOnly={true}></textarea>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                        <div className='row align-items-center m-0'>
                            {
                                !order.id ? (
                                    <div className='col-12 mb-3'>
                                        <div className='d-grid gap-2'>
                                            <button className='btn btn-primary' onClick={onSave}>
                                                <i className='bi bi-check-lg me-2'></i>
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='col-12 mb-3'>
                                            <div className='d-grid gap-2'>
                                                <input type='file' className='d-none' accept=".txt, .pdf, .doc, .xls, .jpg, .xlsx, .docx, .rar, .zip" 
                                                ref={fileSelectRef} onChange={onAddFile} />

                                                <button className='btn btn-primary' onClick={onSelectFile}>
                                                    <i className='bi bi-plus-lg me-2'></i>
                                                    Subir archivo
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                order.order_status?.is_finished ? (
                                    <div className='col-12 mb-3'>
                                        <div className='d-grid gap-2'>
                                            <Link className='btn btn-primary' to={`/pedido/repetir/${order.id}`}>
                                                <i className='bi bi-repeat me-2'></i>
                                                Repetir
                                            </Link>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            <div className='col-12 mb-3'>
                                <div className='d-grid gap-2'>
                                    <Link to={`/pedidos`} className='btn btn-primary'>
                                        <i className='bi bi-chevron-left me-2'></i>
                                        Volver
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {
                            orderBook ? (
                                <div className="modal fade show" tabIndex={-1} style={{
                                    display: 'block',
                                }}>
                                    <div className="modal-dialog modal-fullscreen">
                                        <div className="modal-content bg-dark border-primary">
                                            <div className="modal-header border-primary">
                                                <span className="modal-title">
                                                    Libro
                                                </span>
                                                <button onClick={onCloseOrderBookForm} className='btn btn-primary'>
                                                    <i className='bi bi-x-lg'></i>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row align-items-center mx-0'>
                                                    <div className='col-12 col-md-6'>
                                                        <div className='mb-3'>
                                                            <label className='form-label'>
                                                                Cliente
                                                            </label>
                                                            <select className='form-select' onChange={onChangeOrderBookCustomerId} 
                                                                value={orderBook.customer_id || ""}>
                                                                <option value="">
                                                                    Cliente
                                                                </option>
                                                                {
                                                                    customers.map((customer: CustomerModel) => (
                                                                        <option key={customer.id} value={customer.id}>
                                                                            {customer.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <div className='mb-3'>
                                                            <label className='form-label'>
                                                                Tipo de trámite
                                                            </label>
                                                            <select className='form-select' onChange={onChangeOrderBookOrderTypeId} 
                                                                value={orderBook.order_type_id || ""}>
                                                                <option value="">
                                                                    Tipo de trámite
                                                                </option>
                                                                {
                                                                    orderTypes.map((orderType: OrderTypeModel) => (
                                                                        <option key={orderType.id} value={orderType.id}>
                                                                            {orderType.name} ({orderType.description})
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <div className='mb-3'>
                                                            <label className='form-label'>
                                                                Tipo de libro
                                                            </label>
                                                            <select className='form-select' onChange={onChangeOrderBookBookTypeId} 
                                                                value={orderBook.book_type_id || ""}>
                                                                <option value="">
                                                                    Tipo de libro
                                                                </option>
                                                                {
                                                                    bookTypes.map((bookType: BookTypeModel) => (
                                                                        <option key={bookType.id} value={bookType.id}>
                                                                            {bookType.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <div className='mb-3'>
                                                            <label className='form-label'>
                                                                Nº de libro
                                                            </label>
                                                            <input type='number' className='form-control' min={1} step={1} 
                                                                placeholder='Nº de libro' onChange={onChangeOrderBookNumber} 
                                                                value={orderBook.number || ""} />
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <div className='mb-3'>
                                                            <label className='form-label'>
                                                                Cantidad de folios
                                                            </label>
                                                            <select className='form-select' onChange={onChangeOrderBookFoliosQuantity} 
                                                                value={orderBook.folios_quantity || ""}>
                                                                <option value="">
                                                                    Cantidad de folios
                                                                </option>
                                                                {
                                                                    foliosQuantity.map((folioQuantity: number) => (
                                                                        <option key={folioQuantity} value={folioQuantity}>
                                                                            {folioQuantity}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <div className='mb-3'>
                                                            <label className='form-label'>
                                                                Folio de entrada
                                                            </label>
                                                            <input type='number' className='form-control' min={1} step={1} 
                                                                placeholder='Folio de entrada' onChange={onChangeOrderBookEntryFolio} 
                                                                value={orderBook.entry_folio || ""} />
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <div className='mb-3'>
                                                            <label className='form-label'>
                                                                Sistema de copiado
                                                            </label>
                                                            <select className='form-select' onChange={onChangeOrderBookCopySystemId} 
                                                                value={orderBook.copy_system_id || ""}>
                                                                <option value="">
                                                                    Sistema de copiado
                                                                </option>
                                                                {
                                                                    copySystems.map((copySystem: CopySystemModel) => (
                                                                        <option key={copySystem.id} value={copySystem.id}>
                                                                            {copySystem.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer border-primary">
                                                <div className='d-grid gap-2 w-100'>
                                                    <button className="btn btn-primary" onClick={onSaveOrderBook}>
                                                        <i className='bi bi-check-lg me-2'></i>
                                                        Guardar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }
                    </>
                )
            }
        </>
    );
};

export default OrderScreen;
