import React from 'react';
import { Link } from 'react-router-dom';
import IPrivateAdminScreenProps from '../private.admin.screen.props';

interface IMainAdminScreen extends IPrivateAdminScreenProps {
    onAfterLogout: () => void;
};

function MainAdminScreen(props: IMainAdminScreen) {
    return (
        <div className='row m-0 align-items-center'>
            <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 p-0 mx-auto'>
                <div className='row m-0 align-items-center'>
                    {
                        (props.administrator.role && props.administrator.role.code === "ADM") ? (
                            <Link to={'/admin/inicio/administradores'} className='col-6 p-3 bg-primary text-light text-center text-decoration-none'>
                                <i className='bi bi-person-fill-lock' style={{
                                    fontSize: 50,
                                }}></i>
                                <p className='m-0 text-uppercase fw-bold'>
                                    Administradores
                                </p>
                            </Link>
                        ) : (
                            null
                        )
                    }
                    <Link to={'/admin/inicio/usuarios'} className='col-6 p-3 bg-secondary text-light text-center text-decoration-none'>
                        <i className='bi bi-people-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Usuarios
                        </p>
                    </Link>
                    {
                        (props.administrator.role && props.administrator.role.code === "ADM") ? (
                            <Link to={'/admin/inicio/tipos-libro'} className='col-6 p-3 bg-secondary text-light text-center text-decoration-none'>
                                <i className='bi bi-book-fill' style={{
                                    fontSize: 50,
                                }}></i>
                                <p className='m-0 text-uppercase fw-bold'>
                                    Tipos de libro
                                </p>
                            </Link>
                        ) : (
                            null
                        )
                    }
                    <Link to={'/admin/inicio/pedidos'} className='col-6 p-3 bg-primary text-light text-center text-decoration-none'>
                        <i className='bi bi-briefcase-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Pedidos
                        </p>
                    </Link>
                    <Link to={'/admin/inicio/calendario'} className='col-6 p-3 bg-primary text-light text-center text-decoration-none'>
                        <i className='bi bi-calendar' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Calendario
                        </p>
                    </Link>
                    <Link to={'/admin/inicio/chats'} className='col-6 p-3 bg-secondary text-light text-center text-decoration-none'>
                        <i className='bi bi-chat-left-text-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Chats
                        </p>
                    </Link>
                    <Link to={'/admin/inicio/mis-datos'} className='col-6 p-3 bg-secondary text-light text-center text-decoration-none'>
                        <i className='bi bi-info-circle-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Mis datos
                        </p>
                    </Link>
                    <button className='col-6 p-3 bg-primary text-light text-center border-0' onClick={props.onAfterLogout}>
                        <i className='bi bi-x-circle-fill' style={{
                            fontSize: 50,
                        }}></i>
                        <p className='m-0 text-uppercase fw-bold'>
                            Salir
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MainAdminScreen;
