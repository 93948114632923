import React, { FormEvent, useEffect, useState } from 'react';
import IPrivateScreenProps from '../private.screen.props';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import UserModel from '../../models/user.model';
import IApiResponseModel from '../../models/api.response.model';

const times : string[] = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
];

function MyInformationScreen(props: IPrivateScreenProps) {
    const api = useApi();
    const alert = useAlert();
    
    const [user, setUser] = useState<UserModel>(JSON.parse(JSON.stringify(props.user)));
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    console.log(props.user);

    useEffect(() => {
        setUser(JSON.parse(JSON.stringify(props.user)));
    }, [props.user]);

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            email: event.currentTarget.value,
        });
    };

    const onChangeName = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            name: event.currentTarget.value,
        });
    };

    const onChangeLastName = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            last_name: event.currentTarget.value,
        });
    };

    const onChangeAddress = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            address: event.currentTarget.value,
        });
    };

    const onChangeTelephone = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            telephone: event.currentTarget.value,
        });
    };

    const onChangeVatNumber = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            vat_number: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeStart = (event: FormEvent<HTMLSelectElement>) : void => {
        setUser({
            ...user,
            time_range_start: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeEnd = (event: FormEvent<HTMLSelectElement>) : void => {
        setUser({
            ...user,
            time_range_end: event.currentTarget.value,
        });
    };

    const onChangeCompany = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            company: event.currentTarget.value,
        });
    };

    const onSave = () : void => {
        props.setLoading(true);
        api.PUT<IApiResponseModel>(`/user`, user, props.token).then((response: IApiResponseModel) => {
            alert.SUCCESS(response.message);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    const onChangePassword = (event: FormEvent<HTMLInputElement>) : void => {
        setPassword(event.currentTarget.value || "");
    };

    const onChangeConfirmPassword = (event: FormEvent<HTMLInputElement>) : void => {
        setConfirmPassword(event.currentTarget.value || "");
    };

    const onSavePassword = () : void => {
        props.setLoading(true);
        api.PUT<IApiResponseModel>(`/user/change-password`, {
            password: password,
            confirm_password: confirmPassword,
        }, props.token).then((response: IApiResponseModel) => {
            setPassword('');
            setConfirmPassword('');
            alert.SUCCESS(response.message);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Mis datos
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nombre
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Nombre' onChange={onChangeName} value={user.name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Apellido
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Apellido' onChange={onChangeLastName} value={user.last_name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Correo electrónico
                        </label>
                        <input type='email' className='form-control' maxLength={255} placeholder='Correo electrónico' onChange={onChangeEmail} 
                            value={user.email} />
                    </div>
                </div>
                {
                    !props.user.is_operator ? (
                        <>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Domicilio
                                    </label>
                                    <input type='text' className='form-control' maxLength={100} placeholder='Domicilio' onChange={onChangeAddress} value={user.address} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Teléfono
                                    </label>
                                    <input type='text' className='form-control' maxLength={20} placeholder='Teléfono' onChange={onChangeTelephone} value={user.telephone} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Número de CUIT/CUIL/DNI
                                    </label>
                                    <input type='text' className='form-control' maxLength={11} placeholder='Número de CUIT/CUIL/DNI' onChange={onChangeVatNumber}
                                        value={user.vat_number} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Horario de atención
                                    </label>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <select className='form-select' onChange={onChangeTimeRangeStart} value={user.time_range_start}>
                                                <option value="">
                                                    Desde
                                                </option>
                                                {
                                                    times.map((time: string) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className='col-6'>
                                            <select className='form-select' onChange={onChangeTimeRangeEnd} value={user.time_range_end}>
                                                <option value="">
                                                    Hasta
                                                </option>
                                                {
                                                    times.map((time: string) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Empresa
                                    </label>
                                    <input type='text' className='form-control' maxLength={100} placeholder='Empresa' onChange={onChangeCompany} value={user.company} />
                                </div>
                            </div>
                        </>
                    ) : (
                        null
                    )
                }
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSave}>
                            <i className='bi bi-check-lg me-2'></i>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>

            <div className='row align-items-center mx-0 my-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Cambiar contraseña
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nueva contraseña
                        </label>
                        <input type='password' className='form-control' placeholder='Nueva contraseña' onChange={onChangePassword}
                            value={password} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Confirmar contraseña
                        </label>
                        <input type='password' className='form-control' placeholder='Confirmar contraseña' onChange={onChangeConfirmPassword}
                            value={confirmPassword} />
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSavePassword}>
                            <i className='bi bi-check-lg me-2'></i>
                            Cambiar contraseña
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyInformationScreen;
