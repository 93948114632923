import React from 'react';

interface IProps {};

function FooterComponent(props: IProps) {
    return (
        <div className='row mx-0 align-items-center'>
          <div className='col py-3'>
            <p className='m-0 text-primary font-italic'>
              SMAPP solución integral de copiado contable
            </p>
            <p className='m-0 text-primary font-italic'>
              Todos los derechos reservados a servimaster comercial 2023
            </p>
          </div>
          <div className='col-auto'>
            <a href="https://instagram.com/servimastercomercial?igshid=MzRlODBiNWFlZA==" className='btn btn-link' target='_blank' rel='noreferrer'>
              <i className='bi bi-instagram' style={{
                fontSize: 36,
              }}></i>
            </a>
            <a href="https://www.linkedin.com/in/servimaster-comercial-5353a714a/" className='btn btn-link' target='_blank' rel='noreferrer'>
              <i className='bi bi-linkedin' style={{
                fontSize: 36,
              }}></i>
            </a>
            <a href="https://www.smcomercial.com.ar" className='btn btn-link' target='_blank' rel='noreferrer'>
              <i className='bi bi-shop' style={{
                fontSize: 36,
              }}></i>
            </a>
          </div>
        </div>
    );
}

export default FooterComponent;
