import React, { FormEvent, useEffect, useState } from 'react';
import AdministratorModel from '../../../models/administrator.model';
import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import IApiResponseModel from '../../../models/api.response.model';
import IPrivateAdminScreenProps from '../private.admin.screen.props';

function MyInformationAdminScreen(props: IPrivateAdminScreenProps) {
    const api = useApi();
    const alert = useAlert();
    
    const [administrator, setAdministrator] = useState<AdministratorModel>(JSON.parse(JSON.stringify(props.administrator)));
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    useEffect(() => {
        setAdministrator(JSON.parse(JSON.stringify(props.administrator)));
    }, [props.administrator]);

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setAdministrator({
            ...administrator,
            email: event.currentTarget.value,
        });
    };

    const onChangeName = (event: FormEvent<HTMLInputElement>) : void => {
        setAdministrator({
            ...administrator,
            name: event.currentTarget.value,
        });
    };

    const onChangeLastName = (event: FormEvent<HTMLInputElement>) : void => {
        setAdministrator({
            ...administrator,
            last_name: event.currentTarget.value,
        });
    };

    const onSave = () : void => {
        props.setLoading(true);
        api.PUT<IApiResponseModel>(`/admin/administrator/${administrator.id}`, administrator, props.token).then((response: IApiResponseModel) => {
            alert.SUCCESS(response.message);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    const onChangePassword = (event: FormEvent<HTMLInputElement>) : void => {
        setPassword(event.currentTarget.value || "");
    };

    const onChangeConfirmPassword = (event: FormEvent<HTMLInputElement>) : void => {
        setConfirmPassword(event.currentTarget.value || "");
    };

    const onSavePassword = () : void => {
        props.setLoading(true);
        api.PUT<IApiResponseModel>(`/admin/administrator/${administrator.id}/change-password`, {
            password: password,
            confirm_password: confirmPassword,
        }, props.token).then((response: IApiResponseModel) => {
            setPassword('');
            setConfirmPassword('');
            alert.SUCCESS(response.message);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Mis datos
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nombre
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Nombre' onChange={onChangeName}
                            value={administrator.name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Apellido
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Apellido' onChange={onChangeLastName}
                            value={administrator.last_name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Correo electrónico
                        </label>
                        <input type='email' className='form-control' maxLength={255} placeholder='Correo electrónico' onChange={onChangeEmail} 
                            value={administrator.email} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Rol
                        </label>
                        <input type='text' className='form-control' placeholder='Rol' value={administrator.role?.name} readOnly={true} />
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSave}>
                            <i className='bi bi-check-lg me-2'></i>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>

            <div className='row align-items-center mx-0 my-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Cambiar contraseña
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nueva contraseña
                        </label>
                        <input type='password' className='form-control' placeholder='Nueva contraseña' onChange={onChangePassword}
                            value={password} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Confirmar constraseña
                        </label>
                        <input type='password' className='form-control' placeholder='Confirmar contraseña' onChange={onChangeConfirmPassword}
                            value={confirmPassword} />
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSavePassword}>
                            <i className='bi bi-check-lg me-2'></i>
                            Cambiar contraseña
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyInformationAdminScreen;
