import React, { FormEvent, useEffect, useState } from 'react';

import UserModel from '../../../models/user.model';
import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserMembershipModel from '../../../models/user-membership.model';
import moment from 'moment';
import IPrivateAdminScreenProps from '../private.admin.screen.props';

const times : string[] = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
];

function UserAdminScreen(props: IPrivateAdminScreenProps) {
    let { id } = useParams();

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserModel>({
        id: 0,
        name: "",
        last_name: "",
        email: "",
        address: "",
        telephone: "",
        vat_number: "",
        time_range_start: "",
        time_range_end: "",
        company: "",
        is_operator: false,
        parent_user_id: undefined,
        parent_user: undefined,
    });
    const [userMemberships, setUserMemberships] = useState<UserMembershipModel[]>([]);
    const [showMembershipForm, setShowMembershipForm] = useState<boolean>(false);
    const [membership, setMembership] = useState<UserMembershipModel>();

    useEffect(() => {
        if (id) {
            props.setLoading(true);
            api.GET<UserModel>(`/admin/user/${id}`, props.token).then((response: UserModel) => {
                setUser(response);
                if (props.administrator.role?.code === "ADM" && id) {
                    getMemberships(id).then((response: UserMembershipModel[]) => {
                        setUserMemberships(response);
                        props.setLoading(false);
                    }, (error: string) => {
                        alert.ERROR(error);
                        props.setLoading(false);
                    });
                }
                else {
                    props.setLoading(false);
                }
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            setUser({
                id: 0,
                name: "",
                last_name: "",
                email: "",
                address: "",
                telephone: "",
                vat_number: "",
                time_range_start: "",
                time_range_end: "",
                company: "",
                is_operator: false,
                parent_user_id: undefined,
                parent_user: undefined,
            });
            setUserMemberships([]);
        }

        // eslint-disable-next-line
    }, [id]);

    const getMemberships = (user_id: string) : Promise<UserMembershipModel[]> => {
        return new Promise((resolve, reject) => {
            api.GET<UserMembershipModel[]>(`/admin/user-membership/${user_id}`, props.token).then((response: UserMembershipModel[]) => {
                resolve(response);
            }, (error: string) => {
                reject(error);
            });
        });
    };

    const onChangeName = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            name: event.currentTarget.value,
        });
    };

    const onChangeLastName = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            last_name: event.currentTarget.value,
        });
    };

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            email: event.currentTarget.value,
        });
    };

    const onChangeAddress = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            address: event.currentTarget.value,
        });
    };

    const onChangeTelephone = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            telephone: event.currentTarget.value,
        });
    };

    const onChangeVatNumber = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            vat_number: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeStart = (event: FormEvent<HTMLSelectElement>) : void => {
        setUser({
            ...user,
            time_range_start: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeEnd = (event: FormEvent<HTMLSelectElement>) : void => {
        setUser({
            ...user,
            time_range_end: event.currentTarget.value,
        });
    };

    const onChangeCompany = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            company: event.currentTarget.value,
        });
    };

    const onSave = () : void => {
        props.setLoading(true);
        if (user.id) {
            api.PUT<void>(`/admin/user/${user.id}`, user, props.token).then(() => {
                navigate(`/admin/inicio/usuarios`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            api.POST<void>(`/admin/user`, user, props.token).then(() => {
                navigate(`/admin/inicio/usuarios`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onDelete = () : void => {
        alert.CONFIRM(`¿Confirma que desea eliminar el usuario ${user.name} ${user.last_name}?`).then((response: boolean) => {
            if (response) {
                props.setLoading(true);
                api.DELETE<void>(`/admin/user/${user.id}`, props.token).then(() => {
                    navigate(`/admin/inicio/usuarios`);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }
        });
    };

    const onOpenMembershipForm = (membership?: UserMembershipModel) : void => {
        setMembership(membership ? membership : {
            id: 0,
            user_id: user.id,
            due_date: undefined,
            is_manual: true,
            mp_confirmation_code: "",
        });
        setShowMembershipForm(true);
    };

    const onChangeMembershipDueDate = (event: FormEvent<HTMLInputElement>) : void => {
        if (membership) {
            setMembership({
                ...membership,
                due_date: event.currentTarget.valueAsDate || undefined,
            });
        }
    };

    const onSaveMembership = () : void => {
        if (membership) {
            props.setLoading(true);
            if (membership.id) {
                api.PUT<void>(`/admin/user-membership/${user.id}/${membership.id}`, membership, props.token).then(() => {
                    setShowMembershipForm(false);
                    getMemberships(user.id.toString()).then((response: UserMembershipModel[]) => {
                        setUserMemberships(response);
                        props.setLoading(false);
                    }, (error: string) => {
                        alert.ERROR(error);
                        props.setLoading(false);
                    });
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }
            else {
                api.POST<void>(`/admin/user-membership/${user.id}`, membership, props.token).then(() => {
                    setShowMembershipForm(false);
                    getMemberships(user.id.toString()).then((response: UserMembershipModel[]) => {
                        setUserMemberships(response);
                        props.setLoading(false);
                    }, (error: string) => {
                        alert.ERROR(error);
                        props.setLoading(false);
                    });
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }
        }
    };

    const onDeleteMembership = (user_membership: UserMembershipModel) : void => {
        alert.CONFIRM(`¿Confirma que desea eliminar la membresía del usuario ${user.name} ${user.last_name}?`).then((response: boolean) => {
            if (response) {
                props.setLoading(true);
                api.DELETE<void>(`/admin/user-membership/${user.id}/${user_membership.id}`, props.token).then(() => {
                    getMemberships(user.id.toString()).then((response: UserMembershipModel[]) => {
                        setUserMemberships(response);
                        props.setLoading(false);
                    }, (error: string) => {
                        alert.ERROR(error);
                        props.setLoading(false);
                    });
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        {
                            user.id ? "Editar usuario" : "Nuevo usuario"
                        }
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nombre
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Nombre' onChange={onChangeName} value={user.name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Apellido
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Apellido' onChange={onChangeLastName} value={user.last_name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Correo electrónico
                        </label>
                        <input type='email' className='form-control' maxLength={255} placeholder='Correo electrónico' onChange={onChangeEmail}
                            value={user.email} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Domicilio
                        </label>
                        <input type='text' className='form-control' maxLength={100} placeholder='Domicilio' onChange={onChangeAddress} value={user.address} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Teléfono
                        </label>
                        <input type='text' className='form-control' maxLength={20} placeholder='Teléfono' onChange={onChangeTelephone} value={user.telephone} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Número de CUIT/CUIL/DNI
                        </label>
                        <input type='text' className='form-control' maxLength={11} placeholder='Número de CUIT/CUIL/DNI' onChange={onChangeVatNumber}
                            value={user.vat_number} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Horario de atención
                        </label>
                        <div className='row'>
                            <div className='col-6'>
                                <select className='form-select' onChange={onChangeTimeRangeStart} value={user.time_range_start}>
                                    <option value="">
                                        Desde
                                    </option>
                                    {
                                        times.map((time: string) => (
                                            <option key={time} value={time}>
                                                {time}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='col-6'>
                                <select className='form-select' onChange={onChangeTimeRangeEnd} value={user.time_range_end}>
                                    <option value="">
                                        Hasta
                                    </option>
                                    {
                                        times.map((time: string) => (
                                            <option key={time} value={time}>
                                                {time}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Empresa
                        </label>
                        <input type='text' className='form-control' maxLength={100} placeholder='Empresa' onChange={onChangeCompany} value={user.company} />
                    </div>
                </div>
                {
                    (props.administrator.role?.code === "ADM" && user.id) ? (
                        <div className='col-12'>
                            <div className='mb-3'>
                                <div className='table-responsive'>
                                    <table className='table text-light'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    Membresías
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Fecha de vencimiento
                                                </th>
                                                <th className='text-end'>
                                                    <button className='btn btn-primary' onClick={() => onOpenMembershipForm()}>
                                                        <i className='bi bi-plus-lg'></i>
                                                    </button>
                                                </th>
                                            </tr>
                                            {
                                                userMemberships.map((user_membership: UserMembershipModel) => (
                                                    <tr key={user_membership.id}>
                                                        <td>
                                                            { moment(user_membership.due_date).format("DD/MM/YYYY") }
                                                        </td>
                                                        <td className='text-end'>
                                                            <button className='btn btn-primary me-2' onClick={() => onOpenMembershipForm(user_membership)}>
                                                                <i className='bi bi-pencil-fill'></i>
                                                            </button>
                                                            {
                                                                user_membership.is_manual ? (
                                                                    <button className='btn btn-primary' onClick={() => onDeleteMembership(user_membership)}>
                                                                        <i className='bi bi-trash-fill'></i>
                                                                    </button>
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSave}>
                            <i className='bi bi-check-lg me-2'></i>
                            Guardar
                        </button>
                    </div>
                </div>
                {
                    user.id ? (
                        <div className='col-12 mb-3'>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-primary' onClick={onDelete}>
                                    <i className='bi bi-trash-fill me-2'></i>
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <Link to={`/admin/inicio/usuarios`} className='btn btn-primary'>
                            <i className='bi bi-chevron-left me-2'></i>
                            Volver
                        </Link>
                    </div>
                </div>
            </div>

            {
                showMembershipForm && membership ? (
                    <div className="modal fade show d-block bg-dark">
                        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                            <div className="modal-content bg-dark text-primary">
                                <div className="modal-header border-0">
                                    <h2 className='m-0 text-center text-uppercase text-primary fw-bold'>
                                        { !membership.id ? "Nueva membresía" : "Editar membresía" }
                                    </h2>
                                    <button className="btn btn-link" onClick={() => setShowMembershipForm(false)}>
                                        <i className='bi bi-x-lg' style={{
                                            fontSize: 36,
                                        }}></i>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='mb-3'>
                                        <input type='date' className='form-control' min={255} placeholder='Fecha de vencimiento' 
                                            onChange={onChangeMembershipDueDate} value={membership.due_date ? moment(membership.due_date).format("YYYY-MM-DD") : ""} />
                                    </div>
                                    <div className='d-grid gap-2'>
                                        <button className='btn btn-primary' onClick={onSaveMembership}>
                                            <i className='bi bi-check-lg me-2'></i>
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )
            }
        </>
    );
};

export default UserAdminScreen;
