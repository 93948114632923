import React, { FormEvent, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import IApiResponseModel from '../../models/api.response.model';
import IPublicScreenProps from '../public.screen.props';

function RecoverScreen(props: IPublicScreenProps) {
    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();
    
    const [email, setEmail] = useState<string>('');

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setEmail(event.currentTarget.value);
    };

    const onRecover = () : void => {
        props.setLoading(true);
        api.POST<IApiResponseModel>(`/auth/user/recover`, {
            email: email,
        }).then((response: IApiResponseModel) => {
            setEmail('');
            alert.SUCCESS(response.message).then(() => {
                navigate("/", {
                    replace: true,
                });
            });
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Recuperar contraseña
                    </h2>
                </div>
            </div>
            <div className='mb-3'>
                <input type="email" className='form-control border-primary border-2 rounded' value={email} onChange={onChangeEmail} 
                    placeholder='Correo electrónico' maxLength={255} />
            </div>
            <div className='d-grid gap-2 mb-3'>
                <button className='btn btn-primary text-uppercase' onClick={onRecover}>
                    Recuperar contraseña
                </button>
            </div>
            <div className='text-end'>
                <Link className='btn btn-link' to={`/`}>
                    Iniciar sesión
                </Link>
            </div>
            <div className='text-end'>
                <Link className='btn btn-link' to={`/registro`}>
                    Registrarme
                </Link>
            </div>
        </>
    );
};

export default RecoverScreen;
