import constants from "../../constants/constants";

function useApi() {
    return {
        GET: function<T>(endpoint: string, token?: string) : Promise<T> {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                    method: "GET",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Basic ${token}` : "",
                    },
                }).then((response: Response) => {
                    if (response.status === 200) {
                        response.json().then((json: T) => {
                            resolve(json);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 400) {
                        response.text().then((message: string) => {
                            reject(message);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 401) {
                        reject(constants.NO_PERMISSION);
                    }
                    else {
                        reject(constants.UNEXPECTED_ERROR);
                    }
                }, () => {
                    reject(constants.UNEXPECTED_ERROR);
                });
            });
        },
        GET_FILE: function(endpoint: string, token?: string) : Promise<Blob> {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                    method: "GET",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Basic ${token}` : "",
                    },
                }).then((response: Response) => {
                    if (response.status === 200) {
                        response.blob().then((blob: Blob) => {
                            resolve(blob);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 400) {
                        response.text().then((message: string) => {
                            reject(message);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 401) {
                        reject(constants.NO_PERMISSION);
                    }
                    else {
                        reject(constants.UNEXPECTED_ERROR);
                    }
                }, () => {
                    reject(constants.UNEXPECTED_ERROR);
                });
            });
        },
        POST: function<T>(endpoint: string, data: any = {}, token?: string) : Promise<T> {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                    method: "POST",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Basic ${token}` : "",
                    },
                    body: JSON.stringify(data),
                }).then((response: Response) => {
                    if (response.status === 200) {
                        response.json().then((json: T) => {
                            resolve(json);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 400) {
                        response.text().then((message: string) => {
                            reject(message);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 401) {
                        reject(constants.NO_PERMISSION);
                    }
                    else {
                        reject(constants.UNEXPECTED_ERROR);
                    }
                }, () => {
                    reject(constants.UNEXPECTED_ERROR);
                });
            });
        },
        PUT: function<T>(endpoint: string, data: any = {}, token?: string) : Promise<T> {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                    method: "PUT",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Basic ${token}` : "",
                    },
                    body: JSON.stringify(data),
                }).then((response: Response) => {
                    if (response.status === 200) {
                        response.json().then((json: T) => {
                            resolve(json);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 400) {
                        response.text().then((message: string) => {
                            reject(message);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 401) {
                        reject(constants.NO_PERMISSION);
                    }
                    else {
                        reject(constants.UNEXPECTED_ERROR);
                    }
                }, () => {
                    reject(constants.UNEXPECTED_ERROR);
                });
            });
        },
        DELETE: function<T>(endpoint: string, token?: string) : Promise<T> {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                    method: "DELETE",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Basic ${token}` : "",
                    },
                }).then((response: Response) => {
                    if (response.status === 200) {
                        response.json().then((json: T) => {
                            resolve(json);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 400) {
                        response.text().then((message: string) => {
                            reject(message);
                        }, () => {
                            reject(constants.UNEXPECTED_ERROR);
                        });
                    }
                    else if (response.status === 401) {
                        reject(constants.NO_PERMISSION);
                    }
                    else {
                        reject(constants.UNEXPECTED_ERROR);
                    }
                }, () => {
                    reject(constants.UNEXPECTED_ERROR);
                });
            });
        },
    };
};

export default useApi;