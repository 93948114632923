import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import IPrivateAdminScreenProps from '../private.admin.screen.props';
import OrderModel from '../../../models/order.model';
import FileSelectComponent from '../../../components/file-select/file-select.component';
import moment from 'moment';
import IApiResponseModel from '../../../models/api.response.model';
import OrderFileModel from '../../../models/order-file.model';
import OrderBookModel from '../../../models/order-book.model';

function OrderAdminScreen(props: IPrivateAdminScreenProps) {
    let { id } = useParams();

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const [order, setOrder] = useState<OrderModel>();

    useEffect(() => {
        if (id) {
            props.setLoading(true);
            api.GET<OrderModel>(`/admin/order/${id}`, props.token).then((response: OrderModel) => {
                setOrder(response);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            navigate("/admin/inicio/pedidos");
        }

        // eslint-disable-next-line
    }, [id]);

    const onSave = () : void => {
        if (order && order.order_status && order.order_status.next_order_status_id) {
            props.setLoading(true);
            order.order_status_id = order.order_status.next_order_status_id;
            api.PUT<IApiResponseModel>(`/admin/order/${order.id}`, order, props.token).then((response: IApiResponseModel) => {
                alert.SUCCESS(response.message);
                api.GET<OrderModel>(`/admin/order/${id}`, props.token).then((response: OrderModel) => {
                    setOrder(response);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onSaveObservations = () : void => {
        if (order) {
            props.setLoading(true);
            api.PUT<IApiResponseModel>(`/admin/order/${order.id}/observations`, order, props.token).then((response: IApiResponseModel) => {
                alert.SUCCESS(response.message);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onSaveEstimatedDeliveryDate = () : void => {
        if (order) {
            props.setLoading(true);
            api.PUT<IApiResponseModel>(`/admin/order/${order.id}/estimated-delivery-date`, order, props.token).then((response: IApiResponseModel) => {
                alert.SUCCESS(response.message);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onPause = () : void => {
        if (order && order.order_status && !order.order_status.is_finished && !order.is_paused) {
            props.setLoading(true);
            api.PUT<IApiResponseModel>(`/admin/order/${order.id}/pause`, order, props.token).then((response: IApiResponseModel) => {
                alert.SUCCESS(response.message);
                api.GET<OrderModel>(`/admin/order/${id}`, props.token).then((response: OrderModel) => {
                    setOrder(response);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onUnpause = () : void => {
        if (order && order.order_status && !order.order_status.is_finished && order.is_paused) {
            props.setLoading(true);
            api.PUT<IApiResponseModel>(`/admin/order/${order.id}/unpause`, order, props.token).then((response: IApiResponseModel) => {
                alert.SUCCESS(response.message);
                api.GET<OrderModel>(`/admin/order/${id}`, props.token).then((response: OrderModel) => {
                    setOrder(response);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onPrint = () : void => {
        if (order) {
            props.setLoading(true);
            api.GET_FILE(`/admin/order/${order.id}/print`, props.token).then((response: Blob) => {
                let fileURL : string = URL.createObjectURL(response);
                window.open(fileURL, '_blank');
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onSetNextOrderDeliveryStatus = () : void => {
        if (order && order.order_status?.is_finished && order.next_order_delivery_status) {
            props.setLoading(true);
            api.PUT<IApiResponseModel>(`/admin/order/${order.id}/set-next-order-delivery-status`, {}, props.token).then((response: IApiResponseModel) => {
                alert.SUCCESS(response.message);
                api.GET<OrderModel>(`/admin/order/${id}`, props.token).then((response: OrderModel) => {
                    setOrder(response);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onChangeEstimatedDeliveryDate = (event?: ChangeEvent<HTMLInputElement>) : void => {
        if (order) {
            setOrder({
                ...order,
                estimated_delivery_date: (event && event.target.value) ? moment(event.target.value, 'YYYY-MM-DD').utc(true).toDate() : undefined,
            });
        }
    };

    const onChangeObservations = (event?: ChangeEvent<HTMLTextAreaElement>) : void => {
        if (order) {
            setOrder({
                ...order,
                observations: event ? event.currentTarget.value : "",
            });
        }
    };

    const onChangeScan = (files: string[]) : void => {
        if (order) {
            setOrder({
                ...order,
                scan: files.length ? files[0] : "",
            });
        }
    };

    const userName = () : string => {
        if (order) {
            return `${order.user.name} ${order.user.last_name}`;
        }
        else {
            return "";
        }
    };

    const getOrderFiles = () : string[] => {
        if (order) {
            return order.order_files.map((of: OrderFileModel) => {
                return of.admin_url;
            });
        }
        else {
            return [];
        }
    };

    const getScanFiles = () : string[] => {
        if (order) {
            if (order.order_status?.is_finished) {
                return [
                    order.scan_admin_url,
                ];
            }
            else {
                return order.scan ? [
                    order.scan,
                ] : [];
            }
        }
        else {
            return [];
        }
    };

    const onChangeOrderBookExitFolio = (index: number, value?: number) : void => {
        if (order) {
            let newOrderBooks: OrderBookModel[] = order.order_books;
            newOrderBooks[index].exit_folio = value;

            setOrder({
                ...order,
                order_books: [
                    ...newOrderBooks,
                ],
            });
        }
    };

    return (
        <>
            {
                order ? (
                    <>
                        <div className='row align-items-center mx-0 mb-3'>
                            <div className='col-12'>
                                <h2 className='text-primary'>
                                    Ver pedido
                                </h2>
                            </div>
                        </div>
                        <div className='row align-items-center m-0'>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Fecha
                                    </label>
                                    <input type='text' className='form-control' readOnly={true} value={moment(order.date).format("DD/MM/YYYY")} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Estado
                                    </label>
                                    <input type='text' className='form-control' readOnly={true} value={order.order_status?.name} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Usuario
                                    </label>
                                    <input type='text' className='form-control' readOnly={true} value={userName()} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Correo electrónico
                                    </label>
                                    <input type='email' className='form-control' readOnly={true} value={order.user.email} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Correo electrónico del solicitante
                                    </label>
                                    <input type='email' className='form-control' value={order.applicant_email} readOnly={true} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Nombre del solicitante
                                    </label>
                                    <input type='text' className='form-control' value={order.applicant_name} readOnly={true} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        WhatsApp del solicitante
                                    </label>
                                    <input type='text' className='form-control' value={order.applicant_whatsapp} readOnly={true} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Rango horario
                                    </label>
                                    <input type='text' className='form-control' value={`${order.time_range_start} - ${order.time_range_end}`} 
                                        readOnly={true} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Fecha de retiro
                                    </label>
                                    <input type='date' className='form-control' 
                                        value={order.deliver_date ? moment(order.deliver_date).format("YYYY-MM-DD") : undefined} readOnly={true} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Dirección y localidad de retiro
                                    </label>
                                    <input type='text' maxLength={100} className='form-control' value={order.pickup_address} readOnly={true} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Fecha estimada de entrega
                                    </label>
                                    <div className='input-group'>
                                        <input type='date' min={moment().utc(true).format('YYYY-MM-DD')} className='form-control' 
                                            value={order.estimated_delivery_date ? moment(order.estimated_delivery_date).format('YYYY-MM-DD') : ''} 
                                            onChange={onChangeEstimatedDeliveryDate} readOnly={order.order_status?.is_finished} />
                                        {
                                            !order.order_status?.is_finished ? (
                                                <button className="btn btn-primary" onClick={onSaveEstimatedDeliveryDate}>
                                                    <i className='bi bi-check-lg'></i>
                                                </button>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Archivos
                                    </label>
                                    <FileSelectComponent files={getOrderFiles()} setLoading={props.setLoading} disabled={true} multiple={true} 
                                        token={props.token} accept={['txt', 'pdf', 'doc', 'xls', 'jpg', 'xlsx', 'docx', 'rar', 'zip']} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='mb-3'>
                                    <label className='form-label'>
                                        Libros
                                    </label>
                                    <div className='table-resposive overflow-x-auto'>
                                        <table className='table text-light'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Cliente
                                                    </th>
                                                    <th>
                                                        Tipo de trámite
                                                    </th>
                                                    <th>
                                                        Tipo de libro
                                                    </th>
                                                    <th>
                                                        Nº de libro
                                                    </th>
                                                    <th>
                                                        Cantidad de folios
                                                    </th>
                                                    <th>
                                                        Folio de entrada
                                                    </th>
                                                    <th>
                                                        Folio de salida
                                                    </th>
                                                    <th>
                                                        Sistema de copiado
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    order.order_books.map((orderBook: OrderBookModel, index: number) => (
                                                        <tr key={orderBook.id}>
                                                            <td valign='middle'>
                                                                {orderBook.customer?.name}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.order_type?.name} ({orderBook.order_type?.description})
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.book_type?.name}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.number}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.folios_quantity}
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.entry_folio}
                                                            </td>
                                                            <td valign='middle'>
                                                                <input type='number' className='form-control form-control-sm' 
                                                                    min={orderBook.entry_folio} step={1} value={orderBook.exit_folio || ""} 
                                                                    readOnly={order.order_status?.is_finished}
                                                                    onChange={(event: FormEvent<HTMLInputElement>) => onChangeOrderBookExitFolio(index, event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined)}
                                                                    style={{
                                                                        maxWidth: 120,
                                                                    }} />
                                                            </td>
                                                            <td valign='middle'>
                                                                {orderBook.copy_system?.name}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                (order.order_status?.is_finished || order.order_status?.next_order_status?.is_finished) ? (
                                    <div className='col-12'>
                                        <div className='mb-3'>
                                            <label className='form-label'>
                                                Escaneo
                                            </label>
                                            <FileSelectComponent files={getScanFiles()} setLoading={props.setLoading} onChange={onChangeScan} 
                                                disabled={order.order_status?.is_finished} multiple={false} token={props.token} accept={['pdf', 'jpg']} />
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            {
                                order.id ? (
                                    <div className='col-12'>
                                        <div className='mb-3'>
                                            <label className='form-label'>
                                                Observaciones
                                            </label>
                                            <div className='input-group'>
                                                <textarea className='form-control' rows={5} value={order.observations} onChange={onChangeObservations}></textarea>
                                                <button className="btn btn-primary" onClick={onSaveObservations}>
                                                    <i className='bi bi-check-lg'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                        <div className='row align-items-center m-0'>
                            {
                                !order.is_paused && order.order_status?.next_order_status_id ? (
                                    <div className='col-12 mb-3'>
                                        <div className='d-grid gap-2'>
                                            <button className='btn btn-primary' onClick={onSave}>
                                                <i className='bi bi-check-lg me-2'></i>
                                                { order.order_status?.next_order_status?.name }
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            {
                                !order.is_paused && !order.order_status?.is_finished ? (
                                    <div className='col-12 mb-3'>
                                        <div className='d-grid gap-2'>
                                            <button className='btn btn-primary' onClick={onPause}>
                                                <i className='bi bi-pause me-2'></i>
                                                Pausar
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            {
                                order.is_paused && !order.order_status?.is_finished ? (
                                    <div className='col-12 mb-3'>
                                        <div className='d-grid gap-2'>
                                            <button className='btn btn-primary' onClick={onUnpause}>
                                                <i className='bi bi-play me-2'></i>
                                                Reanudar
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            <div className='col-12 mb-3'>
                                <div className='d-grid gap-2'>
                                    <button className='btn btn-primary' onClick={onPrint}>
                                        <i className='bi bi-printer-fill me-2'></i>
                                        Imprimir
                                    </button>
                                </div>
                            </div>
                            {
                                (order.order_status?.is_finished && order.next_order_delivery_status) ? (
                                    <div className='col-12 mb-3'>
                                        <div className='d-grid gap-2'>
                                            <button className='btn btn-primary' onClick={onSetNextOrderDeliveryStatus}>
                                                <i className='bi bi-truck me-2'></i>
                                                { order.next_order_delivery_status.name }
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            <div className='col-12 mb-3'>
                                <div className='d-grid gap-2'>
                                    <Link to={`/admin/inicio/pedidos`} className='btn btn-primary'>
                                        <i className='bi bi-chevron-left me-2'></i>
                                        Volver
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    null
                )
            }
        </>
    );
};

export default OrderAdminScreen;
