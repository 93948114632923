import React, { FormEvent, createRef, useEffect, useState } from 'react';
import useAlert from '../../hooks/alert/alert.hook';
import constants from '../../constants/constants';
import { Buffer } from 'buffer';
import useApi from '../../hooks/api/api.hook';
import { saveAs } from 'file-saver';

interface IProps {
    files: string[];
    setLoading: (value: boolean) => void;
    onChange?: (files: string[]) => void;
    disabled?: boolean;
    multiple: boolean;
    token: string;
    accept: string[];
};

function FileSelectComponent(props: IProps) {
    const alert = useAlert();
    const api = useApi();

    const [files, setFiles] = useState<string[]>([]);

    let fileSelectRef : React.RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    const toBase64 = (file: File) : Promise<string> => {
        return new Promise((resolve, reject) => {
            let reader: FileReader = new FileReader();
            reader.onload = () : void => {
                if (reader.result) {
                    resolve(reader.result.toString());
                }
                else {
                    reject(constants.UNEXPECTED_ERROR);
                }
            };
            reader.onerror = () : void => {
                reject(constants.UNEXPECTED_ERROR);
            };
            reader.readAsDataURL(file);
        });
    };

    const onSelectFile = () : void => {
        fileSelectRef.current?.click();
    };

    const onAddFile = (event: FormEvent<HTMLInputElement>) : void => {
        if (event.currentTarget.files && event.currentTarget.files.length) {
            props.setLoading(true);
            toBase64(event.currentTarget.files[0]).then((response: string) => {
                if (fileSelectRef.current) {
                    fileSelectRef.current.value = "";
                }
                if (props.multiple) {
                    setFiles([
                        ...files,
                        response,
                    ]);
                    if (props.onChange) {
                        props.onChange([
                            ...files,
                            response,
                        ]);
                    }
                }
                else {
                    setFiles([
                        response,
                    ]);
                    if (props.onChange) {
                        props.onChange([
                            response,
                        ]);
                    }
                }
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onDeleteFile = (index: number) : void => {
        setFiles(files.splice(index, 1));
        if (props.onChange) {
            props.onChange(files.splice(index, 1));
        }
    };

    const getBase64MimeType = (base64: string) : string => {
        let base64Array: string[] = base64.split(';');
        return base64Array[0].split(':')[1].split('/')[1];
    };

    const base64ToBlob = (file: string): Blob => {
        let fileArray : string[] = file.split(',');
        let byteCharacters : string = Buffer.from(fileArray[1], 'base64').toString('ascii');
        let byteNumbers : number[] = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray : Uint8Array = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { 
            type: fileArray[0].replaceAll('data:', '').replaceAll(';base64', ''),
        });
    };

    const onViewFile = (file: string) : void => {
        if (file.includes("base64")) {
            let mimeType: string = getBase64MimeType(file);
            if (mimeType === "pdf") {
                let fileURL : string = URL.createObjectURL(base64ToBlob(file));
                window.open(fileURL, '_blank');
            }
            else if (mimeType === "png" || mimeType === "jpg" || mimeType === "jpeg" || mimeType === "gif") {
                var win : Window | null = window.open('', '_blank');
                win?.document.write('<iframe src="' + file  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            }
            else if (mimeType === "plain") {
                saveAs(base64ToBlob(file));
            }
            else {
                window.open(file, '_blank');
            }
        }
        else {
            props.setLoading(true);
            api.GET_FILE(file, props.token).then((response: Blob) => {
                if (response.type === "text/plain") {
                    saveAs(response);
                    props.setLoading(false);
                }
                else {
                    let fileURL: string = URL.createObjectURL(response);
                    window.open(fileURL, '_blank');
                    props.setLoading(false);
                }
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const getAcceptExtensions = () : string => {
        return props.accept.map((extension: string) => {
            return `.${extension}`;
        }).join(",");
    };
      
    return (
        <>
            <input type='file' className='d-none' accept={getAcceptExtensions()} ref={fileSelectRef} onChange={onAddFile} />

            <div className='row align-items-center mx-0'>
                {
                    files.map((file: string, index: number) => (
                        <div className='col-6 col-sm-4 col-lg-3 col-xl-2 text-center text-dark p-1' key={index}>
                            <div className='bg-tertiary p-1'>
                                <i className='bi bi-file-earmark-text-fill' style={{
                                    fontSize: 48,
                                }}></i>
                                <div className='row align-items-center'>
                                    {
                                        !props.disabled ? (
                                            <>
                                                <div className='col-6'>
                                                    <div className='d-grid gap-2'>
                                                        <button className='btn btn-primary' onClick={() => onViewFile(file)}>
                                                            <i className='bi bi-search'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='d-grid gap-2'>
                                                        <button className='btn btn-primary' onClick={() => onDeleteFile(index)}>
                                                            <i className='bi bi-trash-fill'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className='col-12'>
                                                <div className='d-grid gap-2'>
                                                    <button className='btn btn-primary' onClick={() => onViewFile(file)}>
                                                        <i className='bi bi-search'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                !props.disabled ? (
                    <div className='row align-items-center mt-3'>
                        <div className='col-12'>
                            <div className='d-grid gap-2'>
                                <button className="btn btn-primary" onClick={onSelectFile}>
                                    <i className='bi bi-plus-lg me-2'></i>
                                    Subir archivo
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )
            }
        </>
    );
}

export default FileSelectComponent;
