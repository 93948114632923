import React, { FormEvent, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import IPrivateScreenProps from '../private.screen.props';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import OperatorModel from '../../models/operator.model';

function OperatorsScreen(props: IPrivateScreenProps) {
    const api = useApi();
    const alert = useAlert();

    const [operators, setOperators] = useState<OperatorModel[]>([]);
    const [filteredOperators, setFilteredOperators] = useState<OperatorModel[]>([]);
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        props.setLoading(true);
        api.GET<OperatorModel[]>(`/operator`, props.token).then((response: OperatorModel[]) => {
            setOperators(response);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFilteredOperators(operators.filter((operator: OperatorModel) => {
            return (
                operator.name.toLowerCase().includes(filter.toLowerCase()) ||
                operator.last_name.toLowerCase().includes(filter.toLowerCase()) ||
                operator.email.toLowerCase().includes(filter.toLowerCase())
            );
        }));
    }, [operators, filter]);

    const onChangeFilter = (event: FormEvent<HTMLInputElement>) : void => {
        setFilter(event.currentTarget.value);
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Operadores
                    </h2>
                </div>
            </div>
            <div className='row align-items-center mb-3 mx-0'>
                <div className='col-12 col-md mb-3 mb-md-0'>
                    <input type='text' className='form-control' placeholder='Filtrar...' onChange={onChangeFilter} />
                </div>
                <div className='col-12 col-md-auto'>
                    <div className='d-grid gap-2'>
                        <Link to={`/operador`} className='btn btn-primary'>
                            <i className='bi bi-plus-lg me-2'></i>
                            Nuevo operador
                        </Link>
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                {
                    filteredOperators.length ? (
                        filteredOperators.map((operator: OperatorModel) => (
                            <Link to={`/operador/${operator.id}`} key={operator.id} 
                                className='col-12 col-sm-6 col-md-4 col-lg-3 text-light text-decoration-none my-1'>
                                <div className='bg-light text-dark p-3 text-center'>
                                    <p className='m-0 text-primary'>
                                        <b>
                                            {operator.name} {operator.last_name}
                                        </b>
                                    </p>
                                    <p className='m-0'>
                                        <small>
                                            {operator.email}
                                        </small>
                                    </p>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className='col-12'>
                            <div className='alert alert-warning'>
                                <p className='m-0'>
                                    <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                    No existen operadores registrados que cumplan con los filtros de búsqueda
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default OperatorsScreen;
