import { useEffect, useState } from "react";
import useApi from "../api/api.hook";
import ChatModel from "../../models/chat.model";

export interface INotification {
    title: string;
    text: string;
    link: string;
};

interface INotificationProps {
    token: string;
    is_administrator: boolean;
};

function useNotification(props: INotificationProps) {
    const api = useApi();

    const [notifications, setNotifications] = useState<INotification[]>([]);

    useEffect(() => {
        let interval : NodeJS.Timer = setInterval(() => {
            getNotifications();
        }, 15000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const getNotifications = (): void => {
        if (props.is_administrator) {
            api.GET<ChatModel[]>(`/admin/chat/not-notified`, props.token).then((response: ChatModel[]) => {
                setNotifications(response.map((chat: ChatModel) => {
                    return {
                        title: `Nuevo mensaje`,
                        text: `${chat.user.name} ${chat.user.last_name} ha enviado un nuevo mensaje`,
                        link: `/admin/inicio/chat/${chat.id}`,
                    };
                }));
            }, (error: string) => {
                console.log(error);
                setNotifications([]);
            });
        }
        else {
            api.GET<ChatModel[]>(`/chat/not-notified`, props.token).then((response: ChatModel[]) => {
                setNotifications(response.map((chat: ChatModel) => {
                    return {
                        title: `Nuevo mensaje`,
                        text: `Un administrador te ha enviado un nuevo mensaje`,
                        link: `/chats`,
                    };
                }));
            }, (error: string) => {
                console.log(error);
                setNotifications([]);
            });
        }
    };

    const cleanNotifications = (): void => {
        setNotifications([]);
    };

    return {
        notifications,
        cleanNotifications,
    };
};

export default useNotification;
