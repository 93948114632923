import React, { useEffect, useState } from 'react';
import IPrivateScreenProps from '../private.screen.props';
import UserMembershipModel from '../../models/user-membership.model';
import moment from 'moment';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import PreferenceModel from '../../models/preference.model';

function MembershipScreen(props: IPrivateScreenProps) {
    const api = useApi();
    const alert = useAlert();

    const [membership, setMembership] = useState<UserMembershipModel>();
    const [status, setStatus] = useState<"none" | "due" | "next" | "active">("none");

    useEffect(() => {
        props.setLoading(true);
        api.GET<UserMembershipModel>(`/user-membership`, props.token).then((response: UserMembershipModel) => {
            setMembership(response);
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    }, [props.user]);

    useEffect(() => {
        setStatus(membershipStatus());
        let interval : NodeJS.Timer = setInterval(() => {
            setStatus(membershipStatus());
        }, 1000);

        return () => {
            setStatus("none");
            clearInterval(interval);
        };
    }, [membership]);

    const membershipStatus = () : "none" | "due" | "next" | "active" => {
        if (membership) {
            if (membership.due_date) {
                if (moment(membership.due_date).format("YYYYMMDD") >= moment().format("YYYYMMDD")) {
                    if (parseInt(moment(membership.due_date).format("YYYYMMDD")) - parseInt(moment().format("YYYYMMDD")) >= 30) {
                        return "active";
                    }
                    else {
                        return "next";
                    }
                }
                else {
                    return "due";
                }
            }
            else {
                return "none";
            }
        }
        else {
            return "none";
        }
    };

    const onCreatePayment = () : void => {
        props.setLoading(true);
        api.POST<PreferenceModel>(`/user-membership`, {}, props.token).then((response: PreferenceModel) => {
            window.location.href = response.init_point;
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Membresía
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12'>
                    {
                        membership ? (
                            (status === "none") ? (
                                <div className='alert alert-warning'>
                                    <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                    Actualmente no existe una membresía activa.
                                </div>
                            ) : (status === "due") ? (
                                <div className='alert alert-warning'>
                                    <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                    Su membresía se encuentra vencida desde el día {moment(membership.due_date).format("DD/MM/YYYY")}.
                                </div>
                            ) : (status === "next") ? (
                                <div className='alert alert-warning'>
                                    <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                    Su membresía se encuentra próxima a vencer el día {moment(membership.due_date).format("DD/MM/YYYY")}.
                                </div>
                            ) : (
                                <div className='alert alert-success'>
                                    <i className='bi bi-check-circle-fill me-3'></i>
                                    Su membresía se encuentra activa hasta el día {moment(membership.due_date).format("DD/MM/YYYY")}.
                                </div>
                            )
                        ) : (
                            null
                        )
                    }
                </div>
            </div>
            {
                (status === "none" || status === "due" || status === "next") ? (
                    <div className='row align-items-center m-0'>
                        <div className='col-12 mb-3'>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-primary' onClick={onCreatePayment}>
                                    <i className='bi bi-credit-card-fill me-2'></i>
                                    Pagar membresía
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )
            }
        </>
    );
};

export default MembershipScreen;
