import React, { FormEvent, useEffect, useState } from 'react';

import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import { Link, useNavigate } from 'react-router-dom';
import AdministratorModel from '../../../models/administrator.model';
import IPrivateAdminScreenProps from '../private.admin.screen.props';

function AdministratorsAdminScreen(props: IPrivateAdminScreenProps) {
    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const [administrators, setAdministrators] = useState<AdministratorModel[]>([]);
    const [filteredAdministrators, setFilteredAdministrators] = useState<AdministratorModel[]>([]);
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        if (props.administrator.role && props.administrator.role.code === "ADM") {
            props.setLoading(true);
            api.GET<AdministratorModel[]>(`/admin/administrator`, props.token).then((response: AdministratorModel[]) => {
                setAdministrators(response);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            navigate("/admin/inicio");
        }
    }, [props.administrator]);

    useEffect(() => {
        setFilteredAdministrators(administrators.filter((administrator: AdministratorModel) => {
            return (administrator.email.toLowerCase().includes(filter.toLowerCase()) || administrator.name.toLowerCase().includes(filter.toLowerCase()) ||
                administrator.last_name.toLowerCase().includes(filter.toLowerCase()) || administrator.role?.name.toLowerCase().includes(filter.toLowerCase()));
        }));
    }, [administrators, filter]);

    const onChangeFilter = (event: FormEvent<HTMLInputElement>) : void => {
        setFilter(event.currentTarget.value);
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Administradores
                    </h2>
                </div>
            </div>
            <div className='row align-items-center mb-3 mx-0'>
                <div className='col-12 col-md mb-3 mb-md-0'>
                    <input type='text' className='form-control' placeholder='Filtrar...' onChange={onChangeFilter} />
                </div>
                <div className='col-12 col-md-auto'>
                    <div className='d-grid gap-2'>
                        <Link to={`/admin/inicio/administrador`} className='btn btn-primary'>
                            <i className='bi bi-plus-lg me-2'></i>
                            Nuevo administrador
                        </Link>
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                {
                    filteredAdministrators.length ? (
                        filteredAdministrators.map((administrator: AdministratorModel) => (
                            <Link to={`/admin/inicio/administrador/${administrator.id}`} key={administrator.id} 
                                className='col-12 col-sm-6 col-md-4 col-lg-3 text-light text-decoration-none my-1'>
                                <div className='bg-light text-dark p-3 text-center'>
                                    <p className='m-0 text-primary'>
                                        <b>
                                            {administrator.name} {administrator.last_name}
                                        </b>
                                    </p>
                                    <p className='m-0'>
                                        <small>
                                            {administrator.email}
                                        </small>
                                    </p>
                                    <p className='m-0'>
                                        <small>
                                            {administrator.role?.name}
                                        </small>
                                    </p>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className='col-12'>
                            <div className='alert alert-warning'>
                                <p className='m-0'>
                                    <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                    No existen administradores registrados que cumplan con los filtros de búsqueda
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default AdministratorsAdminScreen;
