import React from 'react';

interface IProps {
    loading: boolean;
};

function LoaderComponent(props: IProps) {
    return props.loading ? (
        <div className='h-100 w-100 position-fixed bg-dark-transparent d-flex flex-column' style={{
            top: 0,
            left: 0,
            zIndex: 9999,
        }}>
            <div className="spinner-border text-primary m-auto" style={{
                width: '3rem',
                height: '3rem',
            }} role="status">
                <span className="visually-hidden">
                    Loading...
                </span>
            </div>
        </div>
    ) : (
        null
    );
}

export default LoaderComponent;
