import React, { FormEvent, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../hooks/api/api.hook';
import UserModel from '../../models/user.model';
import useAlert from '../../hooks/alert/alert.hook';
import IPublicScreenProps from '../public.screen.props';
import IApiResponseModel from '../../models/api.response.model';

const times : string[] = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
];

interface IRegisterScreenProps extends IPublicScreenProps {};

function RegisterScreen(props: IRegisterScreenProps) {
    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();
    
    const [user, setUser] = useState<UserModel>({
        id: 0,
        name: "",
        last_name: "",
        email: "",
        address: "",
        telephone: "",
        vat_number: "",
        time_range_start: "",
        time_range_end: "",
        company: "",
        is_operator: false,
                parent_user_id: undefined,
                parent_user: undefined,
    });
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const onChangeName = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            name: event.currentTarget.value,
        });
    };

    const onChangeLastName = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            last_name: event.currentTarget.value,
        });
    };

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            email: event.currentTarget.value,
        });
    };

    const onChangePassword = (event: FormEvent<HTMLInputElement>) : void => {
        setPassword(event.currentTarget.value);
    };

    const onChangeConfirmPassword = (event: FormEvent<HTMLInputElement>) : void => {
        setConfirmPassword(event.currentTarget.value);
    };

    const onChangeAddress = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            address: event.currentTarget.value,
        });
    };

    const onChangeTelephone = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            telephone: event.currentTarget.value,
        });
    };

    const onChangeVatNumber = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            vat_number: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeStart = (event: FormEvent<HTMLSelectElement>) : void => {
        setUser({
            ...user,
            time_range_start: event.currentTarget.value,
        });
    };

    const onChangeTimeRangeEnd = (event: FormEvent<HTMLSelectElement>) : void => {
        setUser({
            ...user,
            time_range_end: event.currentTarget.value,
        });
    };

    const onChangeCompany = (event: FormEvent<HTMLInputElement>) : void => {
        setUser({
            ...user,
            company: event.currentTarget.value,
        });
    };

    const onRegister = () : void => {
        props.setLoading(true);
        api.POST<IApiResponseModel>(`/user`, {
            name: user.name,
            last_name: user.last_name,
            email: user.email,
            password: password,
            confirm_password: confirmPassword,
            address: user.address,
            telephone: user.telephone,
            vat_number: user.vat_number,
            time_range_start: user.time_range_start,
            time_range_end: user.time_range_end,
            company: user.company,
        }).then((response: IApiResponseModel) => {
            setUser({
                id: 0,
                name: "",
                last_name: "",
                email: "",
                address: "",
                telephone: "",
                vat_number: "",
                time_range_start: "",
                time_range_end: "",
                company: "",
                is_operator: false,
                parent_user_id: undefined,
                parent_user: undefined,
            });
            setPassword('');
            setConfirmPassword('');
            alert.SUCCESS(response.message).then(() => {
                navigate("/", {
                    replace: true,
                });
            });
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Registro
                    </h2>
                </div>
            </div>
            <div className='mb-3'>
                <input type="text" className='form-control border-primary border-2 rounded' value={user.name} onChange={onChangeName} placeholder='Nombre' 
                    maxLength={50} />
            </div>
            <div className='mb-3'>
                <input type="text" className='form-control border-primary border-2 rounded' value={user.last_name} onChange={onChangeLastName} placeholder='Apellido' 
                    maxLength={50} />
            </div>
            <div className='mb-3'>
                <input type="email" className='form-control border-primary border-2 rounded' value={user.email} onChange={onChangeEmail} 
                    placeholder='Correo electrónico' maxLength={255} />
            </div>
            <div className='mb-3'>
                <input type="password" className='form-control border-primary border-2 rounded' value={password} onChange={onChangePassword} 
                    placeholder='Contraseña' />
            </div>
            <div className='mb-3'>
                <input type="password" className='form-control border-primary border-2 rounded' value={confirmPassword} onChange={onChangeConfirmPassword} 
                    placeholder='Confirmar contraseña' />
            </div>
            <div className='mb-3'>
                <input type="text" className='form-control border-primary border-2 rounded' value={user.address} onChange={onChangeAddress} 
                    placeholder='Domicilio' maxLength={100} />
            </div>
            <div className='mb-3'>
                <input type="text" className='form-control border-primary border-2 rounded' value={user.telephone} onChange={onChangeTelephone} 
                    placeholder='Teléfono' maxLength={20} />
            </div>
            <div className='mb-3'>
                <input type="text" className='form-control border-primary border-2 rounded' value={user.vat_number} onChange={onChangeVatNumber} 
                    placeholder='Número de CUIT/CUIL/DNI' maxLength={11} />
            </div>
            <div className='mb-3 text-start'>
                <label className='form-label'>
                    Horario de atención
                </label>
                <div className='row'>
                    <div className='col-6'>
                        <select className='form-select' onChange={onChangeTimeRangeStart} value={user.time_range_start}>
                            <option value="">
                                Desde
                            </option>
                            {
                                times.map((time: string) => (
                                    <option key={time} value={time}>
                                        {time}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-6'>
                        <select className='form-select' onChange={onChangeTimeRangeEnd} value={user.time_range_end}>
                            <option value="">
                                Hasta
                            </option>
                            {
                                times.map((time: string) => (
                                    <option key={time} value={time}>
                                        {time}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='mb-3'>
                <input type="text" className='form-control border-primary border-2 rounded' value={user.company} onChange={onChangeCompany} 
                    placeholder='Empresa o Estudio' maxLength={100} />
            </div>
            <div className='d-grid gap-2 mb-3'>
                <button className='btn btn-primary text-uppercase' onClick={onRegister}>
                    Registrarme
                </button>
            </div>
            <div className='text-end'>
                <Link className='btn btn-link' to={`/`}>
                    Iniciar sesión
                </Link>
            </div>
        </>
    );
};

export default RegisterScreen;
