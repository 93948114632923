interface IConstants {
    UNEXPECTED_ERROR: string;
    NO_PERMISSION: string;
};

const constants : IConstants = {
    UNEXPECTED_ERROR: "Ha ocurrido un error inesperado. Por favor, intente nuevamente.",
    NO_PERMISSION: "No posee permisos para realizar la acción solicitada.",
};

export default constants;
