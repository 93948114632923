import React, { FormEvent, KeyboardEvent, createRef, useEffect, useState } from 'react';

import IPrivateScreenProps from '../private.screen.props';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import ChatModel from '../../models/chat.model';
import ChatMessageModel from '../../models/chat-message.model';
import moment from 'moment';

function ChatsScreen(props: IPrivateScreenProps) {
    const api = useApi();
    const alert = useAlert();

    const chatContainer : React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    const [chat, setChat] = useState<ChatModel>();
    const [chatMessage, setChatMessage] = useState<ChatMessageModel>({
        id: 0,
        chat_id: 0,
        is_administrator: false,
        administrator_id: undefined,
        date: moment().toDate(),
        message: "",
        administrator: undefined,
        notified: false,
    });

    const getChat = () : Promise<ChatModel> => {
        return new Promise((resolve, reject) => {
            api.GET<ChatModel>(`/chat`, props.token).then((response: ChatModel) => {
                resolve(response);
            }, (error: string) => {
                reject(error);
            });
        });
    };

    const refreshChat = () : void => {
        getChat().then((response: ChatModel) => {
            setChat(response);
            setChatMessage({
                ...chatMessage,
                chat_id: response.id,
            });
        }, (error: string) => {
            alert.ERROR(error);
        });
    }

    useEffect(() => {
        refreshChat();
        let interval : NodeJS.Timer = setInterval(() => {
            refreshChat();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (chatContainer && chatContainer.current) {
            const lastChildElement : Element | null = chatContainer.current.lastElementChild;
            if (lastChildElement) {
                lastChildElement.scrollIntoView({ 
                    behavior: 'smooth',
                });
            }
        }
    }, [chatContainer]);

    const onChangeMessage = (event: FormEvent<HTMLTextAreaElement>) : void => {
        setChatMessage({
            ...chatMessage,
            message: event.currentTarget.value,
        });
    };

    const onKeyDownMessage = (event: KeyboardEvent<HTMLTextAreaElement>) : void => {
        if (!event.shiftKey && event.code === "Enter") {
            event.preventDefault();
        }
    };

    const onKeyUpMessage = (event: KeyboardEvent<HTMLTextAreaElement>) : void => {
        if (!event.shiftKey && event.code === "Enter") {
            onSend();
        }
    };

    const onSend = () : void => {
        if (chatMessage.message.trim()) {
            props.setLoading(true);
            api.POST<void>(`/chat`, chatMessage, props.token).then(() => {
                setChatMessage({
                    ...chatMessage,
                    message: "",
                });
                getChat().then((response: ChatModel) => {
                    setChat(response);
                    setChatMessage({
                        id: 0,
                        chat_id: response.id,
                        is_administrator: false,
                        administrator_id: undefined,
                        date: moment().toDate(),
                        message: "",
                        administrator: undefined,
                        notified: false,
                    });
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    return (
        <div className='d-flex flex-column h-100'>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Chats
                    </h2>
                </div>
            </div>
            <div className='row align-items-center mx-0 flex-fill pb-3 overflow-hidden'>
                <div className='h-100 overflow-auto flex-column' ref={chatContainer}>
                    {
                        (chat && chat.id) ? (
                            chat.chat_messages.map((chatMessage: ChatMessageModel) => (
                                <div key={chatMessage.id} >
                                    {
                                        chatMessage.is_administrator ? (
                                            <div className='col-10 col-sm-10 col-md-8 col-lg-5 align-self-start bg-primary text-light my-1 p-2 rounded'>
                                                <small>
                                                    {moment(chatMessage.date).format("DD/MM/YYYY HH:mm:ss")}
                                                </small>
                                                <p className='m-0'>
                                                    {chatMessage.message}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className='col-10 col-sm-10 col-md-8 col-lg-5 align-self-start bg-light text-dark my-1 p-2 rounded ms-auto'>
                                                <small>
                                                    {moment(chatMessage.date).format("DD/MM/YYYY HH:mm:ss")}
                                                </small>
                                                <p className='m-0'>
                                                    {chatMessage.message}
                                                </p>
                                            </div>
                                        )
                                    }
                                    <div className='clearfix'></div>
                                </div>
                            ))
                        ) : (
                            <div className='col-12 align-self-start'>
                                <div className='alert alert-warning'>
                                    <p className='m-0'>
                                        <i className='bi bi-exclamation-triangle-fill me-3'></i>
                                        No hay ningún chat iniciado
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <div className='input-group'>
                        <textarea className='form-control' rows={2} placeholder='Mensaje' onChange={onChangeMessage} onKeyUp={onKeyUpMessage} 
                            onKeyDown={onKeyDownMessage} value={chatMessage.message}></textarea>
                        <button className="btn btn-primary" onClick={onSend}>
                            <i className='bi bi-send-fill'></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatsScreen;
