import React, { FormEvent, useEffect, useState } from 'react';

import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdministratorModel from '../../../models/administrator.model';
import RoleModel from '../../../models/role.model';
import IPrivateAdminScreenProps from '../private.admin.screen.props';
import moment from 'moment';

function AdministratorAdminScreen(props: IPrivateAdminScreenProps) {
    let { id } = useParams();

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const [administrator, setAdministrator] = useState<AdministratorModel>({
        id: 0,
        name: "",
        last_name: "",
        email: "",
        role_id: undefined,
        role: undefined,
        last_chat_notification: moment().utc(true).toDate(),
    });
    const [roles, setRoles] = useState<RoleModel[]>([]);

    useEffect(() => {
        if (props.administrator.role && props.administrator.role.code === "ADM") {
            props.setLoading(true);
            api.GET<RoleModel[]>(`/admin/role`, props.token).then((response: RoleModel[]) => {
                setRoles(response);
                if (id) {
                    api.GET<AdministratorModel>(`/admin/administrator/${id}`, props.token).then((response: AdministratorModel) => {
                        setAdministrator(response);
                        props.setLoading(false);
                    }, (error: string) => {
                        alert.ERROR(error);
                        props.setLoading(false);
                    }); 
                }
                else {
                    setAdministrator({
                        id: 0,
                        name: "",
                        last_name: "",
                        email: "",
                        role_id: undefined,
                        role: undefined,
                        last_chat_notification: moment().utc(true).toDate(),
                    });
                    props.setLoading(false);
                }
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            navigate("/admin/inicio");
        }
    }, [id, props.administrator]);

    const onChangeName = (event: FormEvent<HTMLInputElement>) : void => {
        setAdministrator({
            ...administrator,
            name: event.currentTarget.value,
        });
    };

    const onChangeLastName = (event: FormEvent<HTMLInputElement>) : void => {
        setAdministrator({
            ...administrator,
            last_name: event.currentTarget.value,
        });
    };

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setAdministrator({
            ...administrator,
            email: event.currentTarget.value,
        });
    };

    const onChangeRoleId = (event: FormEvent<HTMLSelectElement>) : void => {
        setAdministrator({
            ...administrator,
            role_id: event.currentTarget.value ? parseInt(event.currentTarget.value) : undefined,
        });
    };

    const onSave = () : void => {
        props.setLoading(true);
        if (administrator.id) {
            api.PUT<void>(`/admin/administrator/${administrator.id}`, administrator, props.token).then(() => {
                navigate(`/admin/inicio/administradores`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            api.POST<void>(`/admin/administrator`, administrator, props.token).then(() => {
                navigate(`/admin/inicio/administradores`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onDelete = () : void => {
        alert.CONFIRM(`¿Confirma que desea eliminar el administrador ${administrator.name} ${administrator.last_name}?`).then((response: boolean) => {
            if (response) {
                props.setLoading(true);
                api.DELETE<void>(`/admin/administrator/${administrator.id}`, props.token).then(() => {
                    navigate(`/admin/inicio/administradores`);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        {
                            administrator.id ? "Editar administrador" : "Nuevo administrador"
                        }
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nombre
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Nombre' onChange={onChangeName} 
                            value={administrator.name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Apellido
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Apellido' onChange={onChangeLastName}
                            value={administrator.last_name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Correo electrónico
                        </label>
                        <input type='email' className='form-control' maxLength={255} placeholder='Correo electrónico' onChange={onChangeEmail}
                            value={administrator.email} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Rol
                        </label>
                        <select className='form-select' value={administrator.role_id} onChange={onChangeRoleId}>
                            <option value={undefined}>
                                Rol
                            </option>
                            {
                                roles.map((role: RoleModel) => (
                                    <option value={role.id} key={role.id}>
                                        { role.name }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSave}>
                            <i className='bi bi-check-lg me-2'></i>
                            Guardar
                        </button>
                    </div>
                </div>
                {
                    (administrator.id && (administrator.id !== props.administrator.id)) ? (
                        <div className='col-12 mb-3'>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-primary' onClick={onDelete}>
                                    <i className='bi bi-trash-fill me-2'></i>
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <Link to={`/admin/inicio/administradores`} className='btn btn-primary'>
                            <i className='bi bi-chevron-left me-2'></i>
                            Volver
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdministratorAdminScreen;
