import React, { FormEvent, useEffect, useState } from 'react';

import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IPrivateAdminScreenProps from '../private.admin.screen.props';
import BookTypeModel from '../../../models/book-type.model';

function BookTypeAdminScreen(props: IPrivateAdminScreenProps) {
    let { id } = useParams();

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const [bookType, setBookType] = useState<BookTypeModel>({
        id: 0,
        name: "",
    });

    useEffect(() => {
        if (props.administrator.role && props.administrator.role.code === "ADM") {
            if (id) {
                props.setLoading(true);
                api.GET<BookTypeModel>(`/admin/book-type/${id}`, props.token).then((response: BookTypeModel) => {
                    setBookType(response);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                }); 
            }
            else {
                setBookType({
                    id: 0,
                    name: "",
                });
            }
        }
        else {
            navigate("/admin/inicio");
        }
    }, [id, props.administrator]);

    const onChangeName = (event: FormEvent<HTMLInputElement>) : void => {
        setBookType({
            ...bookType,
            name: event.currentTarget.value,
        });
    };

    const onSave = () : void => {
        props.setLoading(true);
        if (bookType.id) {
            api.PUT<void>(`/admin/book-type/${bookType.id}`, bookType, props.token).then(() => {
                navigate(`/admin/inicio/tipos-libro`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            api.POST<void>(`/admin/book-type`, bookType, props.token).then(() => {
                navigate(`/admin/inicio/tipos-libro`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onDelete = () : void => {
        alert.CONFIRM(`¿Confirma que desea eliminar el tipo de libro ${bookType.name}?`).then((response: boolean) => {
            if (response) {
                props.setLoading(true);
                api.DELETE<void>(`/admin/book-type/${bookType.id}`, props.token).then(() => {
                    navigate(`/admin/inicio/tipos-libro`);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        {
                            bookType.id ? "Editar tipo de libro" : "Nuevo tipo de libro"
                        }
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nombre
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Nombre' onChange={onChangeName} value={bookType.name} />
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSave}>
                            <i className='bi bi-check-lg me-2'></i>
                            Guardar
                        </button>
                    </div>
                </div>
                {
                    bookType.id ? (
                        <div className='col-12 mb-3'>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-primary' onClick={onDelete}>
                                    <i className='bi bi-trash-fill me-2'></i>
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <Link to={`/admin/inicio/tipos-libro`} className='btn btn-primary'>
                            <i className='bi bi-chevron-left me-2'></i>
                            Volver
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookTypeAdminScreen;
