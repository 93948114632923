import React, { useEffect, useState } from 'react';
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import LoaderComponent from '../../components/loader/loader.component';
import UserModel from '../../models/user.model';
import logo from '../../assets/images/logo.png';
import FooterComponent from '../../components/footer/footer.component';
import useNotification, { INotification } from '../../hooks/notification/notification.hook';
import useAlert from '../../hooks/alert/alert.hook';

interface IPrivateLayoutProps {
  loading: boolean;
  user?: UserModel;
  onAfterLogout: () => void;
  token: string;
};

function PrivateLayout(props: IPrivateLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const notification = useNotification({
    is_administrator: false,
    token: props.token,
  });
  const alert = useAlert();

  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname !== "/chats") {
      if (notification.notifications.length) {
        notification.notifications.forEach((notification: INotification) => {
          alert.NOTIFY(notification);
        });

        notification.cleanNotifications();
      }
    }
    else {
      notification.cleanNotifications();
    }
  }, [location.pathname, notification.notifications]);

  const onNavigate = (url: string) : void => {
    navigate(url);
    setShowMenu(false);
  };
  
  return props.user ? (
    <>
      <div className='container-fluid bg-dark text-light h-100 d-flex flex-column overflow-auto p-0'>
        <div className='row align-items-center m-0 p-3'>
          <div className='col-auto p-0'>
            <Link to="/inicio" replace={true}>
              <img src={logo} alt="" className='img-fluid' height={50} width={50} />
            </Link>
          </div>
          <div className='col p-0'>
            <h1 className='m-0 text-center text-uppercase text-primary fw-bold'>
              Hola {props.user.name}
            </h1>
          </div>
          <div className='col-auto p-0'>
            <button className='btn btn-link p-0' onClick={() => setShowMenu(true)}>
              <i className='bi bi-list' style={{
                fontSize: 50,
              }}></i>
            </button>
          </div>
        </div>
        <div className='row flex-fill m-0 overflow-hidden'>
          <div className='col-12 p-0 h-100 overflow-y-auto overflow-x-hidden'>
            <Outlet />
          </div>
        </div>
        <FooterComponent />
      </div>

      {
        showMenu ? (
          <div className="modal fade show d-block bg-dark">
            <div className="modal-dialog modal-dialog-centered modal-fullscreen">
              <div className="modal-content bg-dark text-primary">
                <div className="modal-header border-0">
                  <h2 className='m-0 text-center text-uppercase text-primary fw-bold'>
                    Menú
                  </h2>
                  <button className="btn btn-link" onClick={() => setShowMenu(false)}>
                    <i className='bi bi-x-lg' style={{
                      fontSize: 36,
                    }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className='row align-items-center m-0'>
                    <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 p-0 mx-auto'>
                      <div className='list-group'>
                        <button onClick={() => onNavigate('/inicio')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-house-door-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Inicio
                          </span>
                        </button>
                        <button onClick={() => onNavigate('/clientes')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-people-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Clientes
                          </span>
                        </button>
                        {
                          !props.user.is_operator ? (
                            <button onClick={() => onNavigate('/operadores')} 
                              className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                              <i className='bi bi-person-fill-gear me-3'></i>
                              <span className='flex-fill text-center'>
                                Operadores
                              </span>
                            </button>
                          ) : (
                            null
                          )
                        }
                        <button onClick={() => onNavigate('/pedidos')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-briefcase-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Pedidos
                          </span>
                        </button>
                        <button onClick={() => onNavigate('/chats')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-chat-left-text-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Chats
                          </span>
                        </button>
                        <button onClick={() => onNavigate('/membresia')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-person-vcard-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Membresía
                          </span>
                        </button>
                        <button onClick={() => onNavigate('/mis-datos')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-info-circle-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Mis datos
                          </span>
                        </button>
                        <button onClick={props.onAfterLogout} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-x-circle-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Salir
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          null
        )
      }

      <LoaderComponent loading={props.loading} />
    </>
  ) : (
    <Navigate to={'/'} replace={true} />
  );
}

export default PrivateLayout;
