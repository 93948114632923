import React, { useMemo } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import LoaderComponent from '../../../components/loader/loader.component';
import AdministratorModel from '../../../models/administrator.model';
import FooterComponent from '../../../components/footer/footer.component';

interface IPublicAdminLayoutProps {
  loading: boolean;
  administrator?: AdministratorModel;
};

function useQuery() {
  const location = useLocation();

  return useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
};

function PublicAdminLayout(props: IPublicAdminLayoutProps) {
  let query = useQuery();

  let redirect : string | null = query.get("redirect");

  return !props.administrator ? (
    <>
      <div className='container-fluid bg-dark text-light h-100 d-flex flex-column overflow-auto p-0'>
        <div className='row flex-fill m-0'>
          <div className='col-12 p-0'>
            <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
              <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 text-center'>
                <Link to="/admin" replace={true}>
                  <img src={logo} alt="" className='img-fluid mb-3' height={200} width={200} />
                </Link>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
      <LoaderComponent loading={props.loading} />
    </>
  ) : (
    redirect ? (
      <Navigate to={redirect} replace={true} />
    ) : (
      <Navigate to={'/admin/inicio'} replace={true} />
    )
  );
}

export default PublicAdminLayout;
