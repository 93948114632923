import React, { FormEvent, useState } from 'react';

import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../hooks/api/api.hook';
import useAlert from '../../../hooks/alert/alert.hook';
import IApiResponseModel from '../../../models/api.response.model';
import IPublicAdminScreenProps from '../public.admin.screen.props copy';

function ResetAdminScreen(props: IPublicAdminScreenProps) {
    let { id, token } = useParams();

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();
    
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const onChangePassword = (event: FormEvent<HTMLInputElement>) : void => {
        setPassword(event.currentTarget.value);
    };

    const onChangeConfirmPassword = (event: FormEvent<HTMLInputElement>) : void => {
        setConfirmPassword(event.currentTarget.value);
    };

    const onReset = () : void => {
        props.setLoading(true);
        api.POST<IApiResponseModel>(`/auth/administrator/reset/${id}/${token}`, {
            password: password,
            confirm_password: confirmPassword,
        }).then((response: IApiResponseModel) => {
            setPassword('');
            setConfirmPassword('');
            alert.SUCCESS(response.message).then(() => {
                navigate("/admin", {
                    replace: true,
                });
            });
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    return (id && token) ? (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Restablecer contraseña
                    </h2>
                </div>
            </div>
            <div className='mb-3'>
                <input type="password" className='form-control border-primary border-2 rounded' value={password} onChange={onChangePassword} 
                    placeholder='Contraseña' />
            </div>
            <div className='mb-3'>
                <input type="password" className='form-control border-primary border-2 rounded' value={confirmPassword} 
                    onChange={onChangeConfirmPassword} placeholder='Confirmar contraseña' />
            </div>
            <div className='d-grid gap-2 mb-3'>
                <button className='btn btn-primary text-uppercase' onClick={onReset}>
                    Cambiar contraseña
                </button>
            </div>
            <div className='text-end'>
                <Link className='btn btn-link' to={`/admin`}>
                    Iniciar sesión
                </Link>
            </div>
        </>
    ) : (
        <Navigate to={'/admin'} replace={true} />
    );
};

export default ResetAdminScreen;
