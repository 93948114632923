import React, { useEffect, useState } from 'react';
import IPrivateScreenProps from '../private.screen.props';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import queryString from 'query-string';
import IApiResponseModel from '../../models/api.response.model';
import { Navigate } from 'react-router-dom';

function MembershipConfirmScreen(props: IPrivateScreenProps) {
    const queryParams = queryString.parse(window.location.search);

    const api = useApi();
    const alert = useAlert();

    const [navigate, setNavigate] = useState<boolean>(false);

    useEffect(() => {
        onSavePayment();
    }, []);

    const onSavePayment = () : void => {
        if (queryParams.payment_id) {
            props.setLoading(true);
            api.PUT<IApiResponseModel>(`/user-membership`, {
                confirmation_code: queryParams.payment_id,
            }, props.token).then((response: IApiResponseModel) => {
                alert.SUCCESS(response.message).then(() => {
                    setNavigate(true);
                });
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error).then(() => {
                    setNavigate(true);
                });
                props.setLoading(false);
            });
        }
        else {
            setNavigate(true);
        }
    };

    return navigate ? (
        <Navigate to={"/membresia"} replace={true} />
    ) : (
        <></>
    );
};

export default MembershipConfirmScreen;
