import React from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import LoaderComponent from '../../components/loader/loader.component';
import logo from '../../assets/images/logo.png';
import UserModel from '../../models/user.model';
import FooterComponent from '../../components/footer/footer.component';

interface IPublicLayoutProps {
  loading: boolean;
  user?: UserModel;
};

function PublicLayout(props: IPublicLayoutProps) {
  return !props.user ? (
    <>
      <div className='container-fluid bg-dark text-light h-100 d-flex flex-column overflow-auto px-0'>
        <div className='row flex-fill mx-0 overflow-hidden'>
          <div className='col-12 px-0 h-100 overflow-auto'>
            <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
              <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 text-center mx-auto'>
                <Link to="/" replace={true}>
                  <img src={logo} alt="" className='img-fluid mb-3' height={200} width={200} />
                </Link>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
      <LoaderComponent loading={props.loading} />
    </>
  ) : (
    <Navigate to={'/inicio'} replace={true} />
  );
}

export default PublicLayout;
