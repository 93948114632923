import React, { useEffect, useState } from 'react';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import LoaderComponent from '../../../components/loader/loader.component';
import AdministratorModel from '../../../models/administrator.model';
import logo from '../../../assets/images/logo.png';
import FooterComponent from '../../../components/footer/footer.component';
import { useLocation } from 'react-router-dom';
import useNotification, { INotification } from '../../../hooks/notification/notification.hook';
import useAlert from '../../../hooks/alert/alert.hook';

interface IPrivateAdminLayoutProps {
  loading: boolean;
  administrator?: AdministratorModel;
  onAfterLogout: () => void;
  token: string;
};

function PrivateAdminLayout(props: IPrivateAdminLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const notification = useNotification({
    is_administrator: true,
    token: props.token,
  });
  const alert = useAlert();

  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    if (!location.pathname.startsWith("/admin/inicio/chat/")) {
      if (notification.notifications.length) {
        notification.notifications.forEach((notification: INotification) => {
          alert.NOTIFY(notification);
        });

        notification.cleanNotifications();
      }
    }
    else {
      notification.cleanNotifications();
    }
  }, [location.pathname, notification.notifications]);

  const onNavigate = (url: string) : void => {
    navigate(url);
    setShowMenu(false);
  };

  return props.administrator ? (
    <>
      <div className='container-fluid bg-dark text-light h-100 d-flex flex-column p-0'>
        <div className='row align-items-center m-0 p-3'>
          <div className='col-auto p-0'>
            <Link to="/admin/inicio" replace={true}>
              <img src={logo} alt="" className='img-fluid' height={50} width={50} />
            </Link>
          </div>
          <div className='col p-0'>
            <h1 className='m-0 text-center text-uppercase text-primary fw-bold'>
              Hola {props.administrator.name}
            </h1>
          </div>
          <div className='col-auto p-0'>
            <button className='btn btn-link p-0' onClick={() => setShowMenu(true)}>
              <i className='bi bi-list' style={{
                fontSize: 50,
              }}></i>
            </button>
          </div>
        </div>
        <div className='row flex-fill m-0 overflow-y-hidden overflow-x-hidden'>
          <div className='col-12 p-0 h-100 overflow-auto'>
            <Outlet />
          </div>
        </div>
        <FooterComponent />
      </div>

      {
        showMenu ? (
          <div className="modal fade show d-block bg-dark">
            <div className="modal-dialog modal-dialog-centered modal-fullscreen">
              <div className="modal-content bg-dark text-primary">
                <div className="modal-header border-0">
                  <h2 className='m-0 text-center text-uppercase text-primary fw-bold'>
                    Menú
                  </h2>
                  <button className="btn btn-link" onClick={() => setShowMenu(false)}>
                    <i className='bi bi-x-lg' style={{
                      fontSize: 36,
                    }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className='row align-items-center m-0'>
                    <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 p-0 mx-auto'>
                      <div className='list-group'>
                        <button onClick={() => onNavigate('/admin/inicio')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-house-door-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Inicio
                          </span>
                        </button>
                        {
                          (props.administrator.role && props.administrator.role.code === "ADM") ? (
                            <button onClick={() => onNavigate('/admin/inicio/administradores')} 
                              className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                              <i className='bi bi-person-fill-lock me-3'></i>
                              <span className='flex-fill text-center'>
                                Administradores
                              </span>
                            </button>
                          ) : (
                            null
                          )
                        }
                        <button onClick={() => onNavigate('/admin/inicio/usuarios')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-people-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Usuarios
                          </span>
                        </button>
                        {
                          (props.administrator.role && props.administrator.role.code === "ADM") ? (
                            <button onClick={() => onNavigate('/admin/inicio/tipos-libro')} 
                              className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                              <i className='bi bi-book-fill me-3'></i>
                              <span className='flex-fill text-center'>
                                Tipos de libro
                              </span>
                            </button>
                          ) : (
                            null
                          )
                        }
                        <button onClick={() => onNavigate('/admin/inicio/pedidos')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-briefcase-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Pedidos
                          </span>
                        </button>
                        <button onClick={() => onNavigate('/admin/inicio/calendario')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-calendar me-3'></i>
                          <span className='flex-fill text-center'>
                            Calendario
                          </span>
                        </button>
                        <button onClick={() => onNavigate('/admin/inicio/chats')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-chat-left-text-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Chats
                          </span>
                        </button>
                        <button onClick={() => onNavigate('/admin/inicio/mis-datos')} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-info-circle-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Mis datos
                          </span>
                        </button>
                        <button onClick={props.onAfterLogout} 
                          className='list-group-item bg-primary border-dark text-light fw-bold d-flex flex-row align-items-center'>
                          <i className='bi bi-x-circle-fill me-3'></i>
                          <span className='flex-fill text-center'>
                            Salir
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          null
        )
      }

      <LoaderComponent loading={props.loading} />
    </>
  ) : (
    (location.pathname === "/admin/inicio") ? (
      <Navigate to={`/admin`} replace={true} />
    ) : (
      <Navigate to={`/admin?redirect=${location.pathname}`} replace={true} />
    )
  );
}

export default PrivateAdminLayout;
