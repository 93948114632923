import React from 'react';
import OrderStatusModel from '../../models/order-status.model';

interface IProps {
    status: OrderStatusModel;
    is_paused: boolean;
};

function OrderStatusComponent(props: IProps) {
    return (
        <div className='row justify-content-end mx-0'>
            <div className='col-auto ps-2 pe-0'>
                {
                    !props.is_paused && props.status.is_pending ? (
                        <div className='bg-danger rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    ) : (
                        <div className='bg-tertiary rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    )
                }
            </div>
            <div className='col-auto ps-2 pe-0'>
                {
                    !props.is_paused && props.status.is_working ? (
                        <div className='bg-warning rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    ) : (
                        <div className='bg-tertiary rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    )
                }
            </div>
            <div className='col-auto ps-2 pe-0'>
                {
                    !props.is_paused && props.status.is_finished ? (
                        <div className='bg-success rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    ) : (
                        <div className='bg-tertiary rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    )
                }
            </div>
            <div className='col-auto ps-2 pe-0'>
                {
                    props.is_paused ? (
                        <div className='bg-orange rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    ) : (
                        <div className='bg-tertiary rounded rounded-circle' style={{
                            height: 20,
                            width: 20,
                        }}></div>
                    )
                }
            </div>
        </div>
    );
}

export default OrderStatusComponent;
