import React, { FormEvent, useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import IPrivateScreenProps from '../private.screen.props';
import useApi from '../../hooks/api/api.hook';
import useAlert from '../../hooks/alert/alert.hook';
import OperatorModel from '../../models/operator.model';

function OperatorScreen(props: IPrivateScreenProps) {
    let { id } = useParams();

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const [operator, setOperator] = useState<OperatorModel>({
        id: 0,
        name: "",
        last_name: "",
        email: "",
        is_operator: true,
        parent_user_id: props.user.id,
        parent_user: props.user,
    });

    useEffect(() => {
        if (id) {
            props.setLoading(true);
            api.GET<OperatorModel>(`/operator/${id}`, props.token).then((response: OperatorModel) => {
                setOperator(response);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            setOperator({
                id: 0,
                name: "",
                last_name: "",
                email: "",
                is_operator: true,
                parent_user_id: props.user.id,
                parent_user: props.user,
            });
        }

        // eslint-disable-next-line
    }, [id, props.user.id]);

    const onChangeName = (event: FormEvent<HTMLInputElement>) : void => {
        setOperator({
            ...operator,
            name: event.currentTarget.value,
        });
    };

    const onChangeLastName = (event: FormEvent<HTMLInputElement>) : void => {
        setOperator({
            ...operator,
            last_name: event.currentTarget.value,
        });
    };

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setOperator({
            ...operator,
            email: event.currentTarget.value,
        });
    };

    const onSave = () : void => {
        props.setLoading(true);
        if (operator.id) {
            api.PUT<void>(`/operator/${operator.id}`, operator, props.token).then(() => {
                navigate(`/operadores`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
        else {
            api.POST<void>(`/operator`, operator, props.token).then(() => {
                navigate(`/operadores`);
                props.setLoading(false);
            }, (error: string) => {
                alert.ERROR(error);
                props.setLoading(false);
            });
        }
    };

    const onDelete = () : void => {
        alert.CONFIRM(`¿Confirma que desea eliminar el operador ${operator.name} ${operator.last_name}?`).then((response: boolean) => {
            if (response) {
                props.setLoading(true);
                api.DELETE<void>(`/operator/${operator.id}`, props.token).then(() => {
                    navigate(`/operadores`);
                    props.setLoading(false);
                }, (error: string) => {
                    alert.ERROR(error);
                    props.setLoading(false);
                });
            }
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        {
                            operator.id ? "Editar operador" : "Nuevo operador"
                        }
                    </h2>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Nombre
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Nombre' onChange={onChangeName} value={operator.name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Apellido
                        </label>
                        <input type='text' className='form-control' maxLength={50} placeholder='Apellido' onChange={onChangeLastName} 
                            value={operator.last_name} />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mb-3'>
                        <label className='form-label'>
                            Correo electrónico
                        </label>
                        <input type='email' className='form-control' maxLength={255} placeholder='Correo electrónico' onChange={onChangeEmail} 
                            value={operator.email} />
                    </div>
                </div>
            </div>
            <div className='row align-items-center m-0'>
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary' onClick={onSave}>
                            <i className='bi bi-check-lg me-2'></i>
                            Guardar
                        </button>
                    </div>
                </div>
                {
                    operator.id ? (
                        <div className='col-12 mb-3'>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-primary' onClick={onDelete}>
                                    <i className='bi bi-trash-fill me-2'></i>
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                <div className='col-12 mb-3'>
                    <div className='d-grid gap-2'>
                        <Link to={`/operadores`} className='btn btn-primary'>
                            <i className='bi bi-chevron-left me-2'></i>
                            Volver
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OperatorScreen;
