import React, { useEffect, useState } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import PublicLayout from './layouts/public/public.layout';
import LoginScreen from './screens/login/login.screen';
import RecoverScreen from './screens/recover/recover.screen';
import ResetScreen from './screens/reset/reset.screen';
import UserModel from './models/user.model';
import PublicAdminLayout from './layouts/admin/public/public.admin.layout';
import AdministratorModel from './models/administrator.model';
import LoginAdminScreen from './screens/admin/login/login.admin.screen';
import RecoverAdminScreen from './screens/admin/recover/recover.admin.screen';
import ResetAdminScreen from './screens/admin/reset/reset.admin.screen';
import PrivateLayout from './layouts/private/private.layout';
import MainScreen from './screens/main/main.screen';
import MainAdminScreen from './screens/admin/main/main.admin.screen';
import PrivateAdminLayout from './layouts/admin/private/private.admin.layout';
import LoaderComponent from './components/loader/loader.component';
import UsersAdminScreen from './screens/admin/users/users.admin.screen';
import UserAdminScreen from './screens/admin/user/user.admin.screen';
import OrdersAdminScreen from './screens/admin/orders/orders.admin.screen';
import ChatsAdminScreen from './screens/admin/chats/chats.admin.screen';
import MyInformationAdminScreen from './screens/admin/my-information/my-information.admin.screen';
import AdministratorsAdminScreen from './screens/admin/administrators/administrators.admin.screen';
import AdministratorAdminScreen from './screens/admin/administrator/administrator.admin.screen';
import CustomersScreen from './screens/customers/customers.screen';
import CustomerScreen from './screens/customer/customer.screen';
import OrdersScreen from './screens/orders/orders.screen';
import OrderScreen from './screens/order/order.screen';
import ChatsScreen from './screens/chats/chats.screen';
import ChatAdminScreen from './screens/admin/chat/chat.admin.screen';
import MyInformationScreen from './screens/my-information/my-information.screen';
import OrderAdminScreen from './screens/admin/order/order.admin.screen';
import MembershipScreen from './screens/membership/membership.screen';
import MembershipConfirmScreen from './screens/membership-confirm/membership-confirm.screen';
import RegisterScreen from './screens/register/register.screen';
import BookTypesAdminScreen from './screens/admin/book-types/book-types.admin.screen';
import BookTypeAdminScreen from './screens/admin/book-type/book-type.admin.screen';
import OrderRepeatScreen from './screens/order-repeat/order-repeat.screen';
import CalendarAdminScreen from './screens/admin/calendar/calendar.admin.screen';
import OperatorsScreen from './screens/operators/operators.screen';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import OperatorScreen from './screens/operator/operator.screen';

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserModel>();
  const [userToken, setUserToken] = useState<string>('');
  const [administrator, setAdministrator] = useState<AdministratorModel | undefined>();
  const [administratorToken, setAdministratorToken] = useState<string>('');
  const [initializing, setInitializing] = useState<boolean>(true);

  useEffect(() => {
    setInitializing(true);

    let u : string | null = sessionStorage.getItem('user');
    let uToken : string | null = sessionStorage.getItem('userToken');
    if (u && uToken) {
      setUser(JSON.parse(u));
      setUserToken(uToken);
    }

    let a : string | null = sessionStorage.getItem('administrator');
    let aToken : string | null = sessionStorage.getItem('administratorToken');
    if (a && aToken) {
      setAdministrator(JSON.parse(a));
      setAdministratorToken(aToken);
    }

    setInitializing(false);
  }, []);

  useEffect(() => {
    if (user && userToken) {
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("userToken", userToken);
    }
    else {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("userToken");
    }
  }, [user, userToken]);

  useEffect(() => {
    if (administrator && administratorToken) {
      sessionStorage.setItem("administrator", JSON.stringify(administrator));
      sessionStorage.setItem("administratorToken", administratorToken);
    }
    else {
      sessionStorage.removeItem("administrator");
      sessionStorage.removeItem("administratorToken");
    }
  }, [administrator, administratorToken]);

  const onUserAfterLogin = (user: UserModel, token: string) : void => {
    setUser(user);
    setUserToken(token);
  };

  const onUserAfterLogout = () : void => {
    setUser(undefined);
    setUserToken('');
  };

  const onAdminAfterLogin = (administrator: AdministratorModel, token: string) : void => {
    setAdministrator(administrator);
    setAdministratorToken(token);
  };

  const onAdminAfterLogout = () : void => {
    setAdministrator(undefined);
    setAdministratorToken('');
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PublicLayout loading={loading} user={user} />,
      children: [
        {
          path: "/",
          element: <LoginScreen setLoading={setLoading} onAfterLogin={onUserAfterLogin}/>,
        },
        {
          path: "/registro",
          element: <RegisterScreen setLoading={setLoading} />,
        },
        {
          path: "/recuperar",
          element: <RecoverScreen setLoading={setLoading} />,
        },
        {
          path: "/resetear/:id/:token",
          element: <ResetScreen setLoading={setLoading} />,
        },
      ], 
    },
    {
      path: "/",
      element: <PrivateLayout loading={loading} user={user} onAfterLogout={onUserAfterLogout} token={userToken} />,
      children: [
        {
          path: "/inicio",
          element: user ? (
            <MainScreen setLoading={setLoading} token={userToken} user={user} onAfterLogout={onUserAfterLogout} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/clientes",
          element: user ? (
            <CustomersScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/cliente/:id?",
          element: user ? (
            <CustomerScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/pedidos",
          element: user ? (
            <OrdersScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/pedido/:id?",
          element: user ? (
            <OrderScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/pedido/repetir/:id?",
          element: user ? (
            <OrderRepeatScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/chats",
          element: user ? (
            <ChatsScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/mis-datos",
          element: user ? (
            <MyInformationScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/membresia",
          element: user ? (
            <MembershipScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/membresia/confirmar",
          element: user ? (
            <MembershipConfirmScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/operadores",
          element: user ? (
            <OperatorsScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/operador/:id?",
          element: user ? (
            <OperatorScreen setLoading={setLoading} token={userToken} user={user} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
      ], 
    },
    {
      path: "/admin",
      element: <PublicAdminLayout loading={loading} administrator={administrator} />,
      children: [
        {
          path: "/admin",
          element: <LoginAdminScreen setLoading={setLoading} onAfterLogin={onAdminAfterLogin} />,
        },
        {
          path: "/admin/recuperar",
          element: <RecoverAdminScreen setLoading={setLoading} />,
        },
        {
          path: "/admin/resetear/:id/:token",
          element: <ResetAdminScreen setLoading={setLoading} />,
        },
      ], 
    },
    {
      path: "/admin/inicio",
      element: <PrivateAdminLayout loading={loading} administrator={administrator} onAfterLogout={onAdminAfterLogout} token={administratorToken} />,
      children: [
        {
          path: "/admin/inicio",
          element: administrator ? (
            <MainAdminScreen setLoading={setLoading} token={administratorToken} onAfterLogout={onAdminAfterLogout} administrator={administrator} />
          ) : (
            <Navigate to="/admin" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/administradores",
          element: administrator ? (
            <AdministratorsAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/administrador/:id?",
          element: administrator ? (
            <AdministratorAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/usuarios",
          element: administrator ? (
            <UsersAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/usuario/:id?",
          element: administrator ? (
            <UserAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/tipos-libro",
          element: administrator ? (
            <BookTypesAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/tipo-libro/:id?",
          element: administrator ? (
            <BookTypeAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/pedidos",
          element: administrator ? (
            <OrdersAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/pedido/:id",
          element: administrator ? (
            <OrderAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/calendario",
          element: administrator ? (
            <CalendarAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/chats",
          element: administrator ? (
            <ChatsAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/chat/:id",
          element: administrator ? (
            <ChatAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
        {
          path: "/admin/inicio/mis-datos",
          element: administrator ? (
            <MyInformationAdminScreen setLoading={setLoading} token={administratorToken} administrator={administrator} />
          ) : (
            <Navigate to="/" replace={true} />
          ),
        },
      ], 
    },
  ]);
  
  return initializing ? (
    <LoaderComponent loading={true} />
  ) : (
    <RouterProvider router={router} />
  );
};

export default App;
