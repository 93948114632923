import Swal, { SweetAlertResult } from "sweetalert2";
import { INotification } from "../notification/notification.hook";

function useAlert() {
    return {
        ERROR: (message: string) : Promise<void> => {
            return new Promise((resolve) => {
                Swal.fire({
                    titleText: "ERROR",
                    text: message,
                    icon: 'error',
                    iconColor: "#DC3545",
                    backdrop: true,
                    color: "#DC3545",
                    background: "#ffffff",
                    position: 'center',
                    heightAuto: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: "OK",
                    confirmButtonColor: "#DC3545",
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                    reverseButtons: false,
                    focusConfirm: true,
                    returnFocus: true,
                    showCloseButton: false,
                }).then(() => {
                    resolve();
                });
            });
        },
        SUCCESS: (message: string) : Promise<void> => {
            return new Promise((resolve) => {
                Swal.fire({
                    titleText: "OK",
                    text: message,
                    icon: 'success',
                    iconColor: "#26A744",
                    backdrop: true,
                    color: "#26A744",
                    background: "#ffffff",
                    position: 'center',
                    heightAuto: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: "OK",
                    confirmButtonColor: "#26A744",
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                    reverseButtons: false,
                    focusConfirm: true,
                    returnFocus: true,
                    showCloseButton: false,
                }).then(() => {
                    resolve();
                });
            });
        },
        CONFIRM: (message: string) : Promise<boolean> => {
            return new Promise((resolve) => {
                Swal.fire({
                    titleText: "",
                    text: message,
                    icon: 'question',
                    iconColor: "#17a2b8",
                    backdrop: true,
                    color: "#17a2b8",
                    background: "#ffffff",
                    position: 'center',
                    heightAuto: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "SI",
                    confirmButtonColor: "#ffc107",
                    denyButtonText: "NO",
                    denyButtonColor: "#000000",
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success mx-1',
                        denyButton: 'btn btn-dark mx-1',
                    },
                    reverseButtons: false,
                    focusConfirm: true,
                    returnFocus: true,
                    showCloseButton: false,
                }).then((response: SweetAlertResult<any>) => {
                    if (response.isConfirmed) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            });
        },
        NOTIFY: (notification: INotification) : Promise<boolean> => {
            return new Promise((resolve) => {
                Swal.fire({
                    titleText: notification.title,
                    text: "",
                    icon: undefined,
                    iconColor: "",
                    backdrop: false,
                    color: "#13CAF0",
                    background: "#ffffff",
                    position: 'bottom-right',
                    heightAuto: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: "",
                    confirmButtonColor: "",
                    denyButtonText: "",
                    denyButtonColor: "",
                    buttonsStyling: false,
                    customClass: {},
                    reverseButtons: false,
                    focusConfirm: false,
                    returnFocus: true,
                    showCloseButton: true,
                    html: `<a href='${notification.link}'>${notification.text}</a>`,
                    timer: 10000,
                    timerProgressBar: false,
                });
            });
        },
    };
};

export default useAlert;