import React, { FormEvent, useState } from 'react';

import { Link } from 'react-router-dom';
import useApi from '../../hooks/api/api.hook';
import UserModel from '../../models/user.model';
import useAlert from '../../hooks/alert/alert.hook';
import { Buffer } from 'buffer';
import IPublicScreenProps from '../public.screen.props';

interface ILoginScreenProps extends IPublicScreenProps {
    onAfterLogin: (user: UserModel, token: string) => void;
};

function LoginScreen(props: ILoginScreenProps) {
    const api = useApi();
    const alert = useAlert();
    
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const onChangeEmail = (event: FormEvent<HTMLInputElement>) : void => {
        setEmail(event.currentTarget.value);
    };

    const onChangePassword = (event: FormEvent<HTMLInputElement>) : void => {
        setPassword(event.currentTarget.value);
    };

    const onLogin = () : void => {
        props.setLoading(true);
        api.POST<UserModel>(`/auth/user/login`, {
            email: email,
            password: password,
        }).then((response: UserModel) => {
            props.onAfterLogin(response, Buffer.from(`${email}:${password}`).toString('base64'));
            props.setLoading(false);
        }, (error: string) => {
            alert.ERROR(error);
            props.setLoading(false);
        });
    };

    return (
        <>
            <div className='row align-items-center mx-0 mb-3'>
                <div className='col-12'>
                    <h2 className='text-primary'>
                        Iniciar sesión
                    </h2>
                </div>
            </div>
            <div className='mb-3'>
                <input type="email" className='form-control border-primary border-2 rounded' value={email} onChange={onChangeEmail} 
                    placeholder='Correo electrónico' maxLength={255} />
            </div>
            <div className='input-group mb-3'>
                <input type={showPassword ? "text" : "password"} className='form-control border-primary border-2 rounded-left' value={password} onChange={onChangePassword} 
                    placeholder='Contraseña' />
                <button className="btn btn-outline-primary border-2" onClick={() => setShowPassword(!showPassword)}>
                    {
                        showPassword ? (
                            <i className='bi bi-eye-slash'></i>
                        ) : (
                            <i className='bi bi-eye'></i>
                        )
                    }
                </button>
            </div>
            <div className='d-grid gap-2 mb-3'>
                <button className='btn btn-primary text-uppercase' onClick={onLogin}>
                    Iniciar sesión
                </button>
            </div>
            <div className='text-end'>
                <Link className='btn btn-link' to={`/recuperar`}>
                    Olvidé mi contraseña
                </Link>
            </div>
            <div className='text-end'>
                <Link className='btn btn-link' to={`/registro`}>
                    Registrarme
                </Link>
            </div>
        </>
    );
};

export default LoginScreen;
